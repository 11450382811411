import ReactGA from "react-ga4";



class AnalyticsService {

    analytics_tag = process.env.REACT_APP_GOOGLE_ANALYTICS_TAG || "";

    hasStarted = false;

    startAnalytics() {
        if (this.hasStarted || !this.analytics_tag) {
            return;
        }

        ReactGA.initialize(this.analytics_tag);
        this.hasStarted = true;
    }

    stopAnalytics(){
        ReactGA.reset();
        this.hasStarted = false;
    }


    logPageView(path: string) {
        if(this.hasStarted){
            ReactGA.send({ hitType: "pageview", page: path });
        }
    }



}

const analyticsService = new AnalyticsService();
export default analyticsService;