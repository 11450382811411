import { Box, Button, Container, Typography } from "@mui/material";
import {Link as RouterLink} from "react-router-dom";


export default function ConfirmEmailSuccess() {

    return (
        <Container>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "60vh", flexDirection: "column" }}>
                <Typography variant="h1">Email Confirmed</Typography>
                <Typography variant="body1">Your email has been confirmed, you can now login to your account.</Typography>
                <Button variant="contained" color="secondary" sx={{marginTop: "20px"}} fullWidth
                    component={RouterLink} to="/login"
                >Login</Button>
            </Box>
        </Container>
    );
}