import React from "react";
import soft from "../images/tyres/Soft.png";
import medium from "../images/tyres/Medium.png";
import hard from "../images/tyres/Hard.png";
import wet from "../images/tyres/Wet.png";
import inter from "../images/tyres/Inter.png";
import "./tyre.css"




export class TyreImageComponent extends React.Component<{ tyre: number }> {

    render() {

        let tyreImage: string;

        switch (this.props.tyre) {
            case 16:
                tyreImage = soft;
                break;
            case 17:
                tyreImage = medium;
                break;
            case 18:
                tyreImage = hard;
                break;
            case 8:
                tyreImage = wet;
                break;
            case 7:
                tyreImage = inter;
                break;
            default:
                tyreImage = soft;
                break;
        }

        return <img className="tyreImage" src={tyreImage} alt="Tyre" />;
    }
}