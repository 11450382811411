import React, { Component } from "react";
import "./sessions.scss";
import { Link } from "react-router-dom";
import { TrackInfoComponent } from "../components/TrackInfoComponent";
import { Session, SessionsClient } from "../ApiClient";
import { Box, CircularProgress, Container, Grid, Typography } from "@mui/material";
import { getSessionNameFromType } from "../shared/utils";

type State = {
    sessions: Session[],
    loading: boolean,
}

export class SessionsComponent extends Component<{}, State> {

    constructor(props: {}) {

        super(props);
        this.state = { sessions: [] ,
            loading: true};
    }

    componentDidMount() {

        document.title = "Pitwall Companion - Sessions";
        let sessionClient = new SessionsClient();
        sessionClient.get().then((response) => {
            this.setState({ sessions: response, loading: false});
        }).catch((error) => {
            
        });
    }



    dummyOptions = [
        { id: 1, label: 'Hello World' },
        { id: 2, label: 'Goodbye World' }
    ];

    render() {
        return (!this.state.loading ? <div>
            {/* <Container>
                <Autocomplete renderInput={(params: AutocompleteRenderInputParams) => 
                <TextField {...params} label="User"/>}
                options={this.dummyOptions} />
            </Container> */}
            <div className="sessions">
            {this.state.sessions.map((session,idx) => {
                let startDate = new Date(session.sessionStartTime!);
                let endDate = new Date(session.sessionEndTime!);
                let duration = endDate.getTime() - startDate.getTime();
                let durationStr = new Date(duration).toISOString().substring(11, 19);
                let formatter = new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false, weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'});
                return <div key={idx} className="session">
                    <Link to={"/sessions/"+session.id}>
                        <Container>
                            <Grid container spacing={1} columns={16}>
                                <Grid item xs={2} sm={2}>
                                    <TrackInfoComponent track={session.trackId!}></TrackInfoComponent>
                                </Grid>
                                <Grid item xs={2} sm={2} sx={{display:"flex", justifyContent:"center", justifyItems:"center", alignItems:"center"}}>
                                    <Typography variant="h6">Laps: {session.numberOfLaps}</Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} sx={{display:"flex", justifyContent:"center", justifyItems:"center", alignItems:"center"}}>
                                    <Typography variant="h6">{getSessionNameFromType(session.sessionType!)}</Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} sx={{display:"flex", justifyContent:"center", justifyItems:"center", alignItems:"center"}}>
                                    <Typography variant="h6">User: {session.userId}</Typography>
                                </Grid>
                                <Grid item xs={2} sm={4} sx={{display:"flex", justifyContent:"center", justifyItems:"center", alignItems:"center"}}>
                                    {/* <Box sx={{ display: 'flex', justifyContent:"center", alignItems:"center", flexDirection:"column" }}>
                                        <Typography variant="h6">Start:{startDate.toLocaleString()}</Typography>
                                        <Typography variant="h6">End:{endDate.toLocaleString()} </Typography>
                                    </Box> */}
                                     <Typography variant="h6">{formatter.format(startDate)}</Typography>
                                </Grid> 
                                <Grid item xs={2} sm={4} sx={{display:"flex", justifyContent:"center", justifyItems:"center", alignItems:"center"}}>
                                    {/* <Typography variant="h6">Duration: {`${durationStr.getHours()}:${durationStr.getMinutes()}`}</Typography> */}
                                    <Typography variant="h6">Duration: {durationStr}</Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    </Link>
                    </div>
            }) }
        </div>
        </div>
        : <Container maxWidth="md" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "90vh" }}>
            <Box sx={{ display: 'flex' }}>
                <CircularProgress size={100} color="secondary"/>
            </Box>
        </Container>);
    }
}



export function SessionRow ({session, showUser=true, format="full"}: {session: Session, showUser?:boolean, format?: "compact" | "full"}) {
    
    let startDate = new Date(session.sessionStartTime!);
    let endDate = new Date(session.sessionEndTime!);
    let duration = endDate.getTime() - startDate.getTime();
    let durationStr = new Date(duration).toISOString().substring(11, 19);
    let formatter = new Intl.DateTimeFormat(navigator.language, { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false, weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'});
    let columns = showUser ? 17 : 15;
    return <div className="session">
        <Link to={"/sessions/"+session.id}>
            <Container>
                <Grid container spacing={1} columns={columns}>
                    <Grid item xs={3} sm={3}>
                        <TrackInfoComponent track={session.trackId!} format={format}></TrackInfoComponent>
                    </Grid>
                    <Grid item xs={2} sm={2} sx={{display:"flex", justifyContent:"center", justifyItems:"center", alignItems:"center"}}>
                        <Typography variant="h6">Laps: {session.numberOfLaps}</Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} sx={{display:"flex", justifyContent:"center", justifyItems:"center", alignItems:"center"}}>
                        <Typography variant="h6">{getSessionNameFromType(session.sessionType!)}</Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} sx={{justifyContent:"center", justifyItems:"center", alignItems:"center"}} display={showUser ? "flex": "none"}>
                        <Typography variant="h6">User: {session.userId}</Typography>
                    </Grid>
                    <Grid item xs={2} sm={4} sx={{display:"flex", justifyContent:"center", justifyItems:"center", alignItems:"center"}}>
                        {/* <Box sx={{ display: 'flex', justifyContent:"center", alignItems:"center", flexDirection:"column" }}>
                            <Typography variant="h6">Start:{startDate.toLocaleString()}</Typography>
                            <Typography variant="h6">End:{endDate.toLocaleString()} </Typography>
                        </Box> */}
                            <Typography variant="h6">{formatter.format(startDate)}</Typography>
                    </Grid> 
                    <Grid item xs={2} sm={4} sx={{display:"flex", justifyContent:"center", justifyItems:"center", alignItems:"center"}}>
                        {/* <Typography variant="h6">Duration: {`${durationStr.getHours()}:${durationStr.getMinutes()}`}</Typography> */}
                        <Typography variant="h6">Duration: {durationStr}</Typography>
                    </Grid>
                </Grid>
            </Container>
        </Link>
        </div>
}