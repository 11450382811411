import React from "react";
import clear from "../images/weather/Clear.png";
import lightCloud from "../images/weather/Light Cloud.png";
import overcast from "../images/weather/Overcast.png";
import lightRain from "../images/weather/Light Rain.png";
import heavyRain from "../images/weather/Heavy Rain.png";
import storm from "../images/weather/Storm.png";
import { WeatherCondition } from "../ApiClient";


export class WeatherImageComponent extends React.Component<{ weather: WeatherCondition, className?: string }> {


    render(){

        let weatherImage: string;

        switch(this.props.weather){
            case 0:
                weatherImage = clear;
                break;
            case 1:
                weatherImage = lightCloud;
                break;
            case 2:
                weatherImage = overcast;
                break;
            case 3:
                weatherImage = lightRain;
                break;
            case 4:
                weatherImage = heavyRain;
                break;
            case 5:
                weatherImage = storm;
                break;
            default:
                weatherImage = clear;
                break;
        }

        return <img className={this.props.className} src={weatherImage} alt="Weather" />;
    }
}