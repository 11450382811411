import React, { Component } from "react";
import "./sessions.scss";
import { Link } from "react-router-dom";
import { TrackInfoComponent } from "../components/TrackInfoComponent";
import { Container, Typography } from "@mui/material";
import { LiveSessionClient, Session } from "../ApiClient";
import { getSessionNameFromType } from "../shared/utils";

type State = {
    sessions: Session[]
}

export class LiveSessionsComponent extends Component<{}, State> {

    private client: LiveSessionClient;
    private interval: NodeJS.Timeout | null = null;
    constructor(props: {}) {

        super(props);
        this.state = { sessions: [] };
        this.client = new LiveSessionClient();
    }

    componentDidMount() {

        document.title = "Pitwall Companion - Live Sessions";
        //get session data from /api/sessions 
        //and display it in a table
        this.client.getAllSessions().then(sessions => {
            this.setState({ sessions: sessions });
        }).catch((error) => {
            //TODO: handle error
        });

        //refresh every 5 seconds
        this.interval = setInterval(() => {
            this.client.getAllSessions().then(sessions => {
                this.setState({ sessions: sessions });
            }).catch((error) => {
                //TODO: handle error
            });
        }, 5000);

    }

    componentWillUnmount(): void {
        //cleanup
        if (this.interval) {
            clearInterval(this.interval);
        }
    }


    render() {

        //if there are no sessions, display a message
        if (this.state.sessions.length === 0) {
            return (
                <Container maxWidth="md" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "90vh" }}>
                    <div>
                        <Typography variant="h2">No live sessions</Typography>
                        <Typography variant="h4">Start racing with the app open to see your race session here.</Typography>
                    </div>
                </Container>
            );
        }

        return (<div className="sessions">
            {this.state.sessions.map((session,idx) => {
                let startDate = new Date(session.sessionStartTime!);
                let endDate = new Date(session.sessionEndTime!);
                return <div key={idx} className="session">
                        <Link to={"/live/"+session.id}>
                            <div className="sessionContent">
                            <TrackInfoComponent track={session.trackId!}></TrackInfoComponent> 
                            <div> Laps: {session.numberOfLaps} </div>
                            <div> {getSessionNameFromType(session.sessionType!)}  </div>
                            <div> User: {session.userId}</div>
                            <div className="sessionTimeOverview">
                                <div>Start:{startDate.toLocaleString()}</div>
                                <div>End:{endDate.toLocaleString()} </div>
                                </div>
                            </div>
                        </Link>
                    </div>
            }) }
        </div>);
    }
}