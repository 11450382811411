import { Box, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Session, SessionsClient, UserInfo } from "../ApiClient";
import { RootState } from "../store";
import { Button } from "@mui/material";
import React, { useEffect } from "react";
import authService from "../services/AuthService";
import { push } from "redux-first-history";
import { formatDateTime, getSessionNameFromType } from "../shared/utils";
import { TrackInfoComponent } from "../components/TrackInfoComponent";
import DeleteIcon from '@mui/icons-material/Delete';

export default function UserAccount() {

    const dispatch = useDispatch();
  
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const handleDelete = () => {
        authService.deleteAccount().then(() => {
            dispatch(push('/login') as any);
        });
    };

    const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);
    const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

    const [openChangePasswordDialog, setOpenChangePasswordDialog] = React.useState(false);
    const handleOpenChangePasswordDialog = () => setOpenChangePasswordDialog(true);
    const handleCloseChangePasswordDialog = () => setOpenChangePasswordDialog(false);

    const [passwordChangeErrors, setPasswordChangeErrors] = React.useState([] as string[]);

    const handleChangePassword = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        const newPassword = data.get('newPassword') as string;
        const oldPassword = data.get('oldPassword') as string;
        const confirmNewPassword = data.get('confirmNewPassword') as string;

        if(newPassword !== confirmNewPassword){
            setPasswordChangeErrors(["New passwords do not match"]);
            return;
        }
        authService.changePassword(newPassword, oldPassword).then((response) => {
            //TODO display success message
            setPasswordChangeErrors([]);
            handleCloseChangePasswordDialog();
            handleOpenPasswordChangeSuccessDialog();
            return;
        }).catch((error) => {
            setPasswordChangeErrors(Object.entries(error.errors).map( (entry, err) => (entry[1] as any)[0]));
        });
    };

    const [openPasswordChangeSuccessDialog, setOpenPasswordChangeSuccessDialog] = React.useState(false);

    const handleOpenPasswordChangeSuccessDialog = () => setOpenPasswordChangeSuccessDialog(true);
    const handleClosePasswordChangeSuccessDialog = () => setOpenPasswordChangeSuccessDialog(false);

    const user:UserInfo|null = useSelector((state: RootState) => state.loggedIn.userInfo);

    if(user === null){
        return <div></div>
    }


    return (
        <Container maxWidth="lg" sx={{display: "flex", flexDirection:"column"}}>
            <Paper sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'start', padding:4, borderRadius:"5px" }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: "100%" }}>
                    <Typography component="h1" variant="h5">
                        Profile
                    </Typography>
                </Box>
                <Box sx={{ marginTop: 4, display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                    <Typography component="h1" variant="h6">
                        Name: {user.name}
                    </Typography>
                    <Typography component="h1" variant="h6">
                        Email: {user.email}
                    </Typography>
                    <Typography component="h1" variant="h6">
                        Created at: {user.createdAt?.toLocaleDateString()}
                    </Typography>
                </Box>
            </Paper>
            <Box sx={{ margin: "20px 0", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-evenly" }}>
            <Button variant="contained" color="primary" sx={{ maxWidth: "fit-content"  }} onClick={handleOpenChangePasswordDialog}>
                Change Password
            </Button>
            <Button variant="contained" color="error" sx={{ maxWidth: "fit-content" }} onClick={handleOpenDeleteDialog}>
                Delete Account
            </Button>
            </Box>
            <Dialog
                open={openChangePasswordDialog}
                onClose={handleCloseChangePasswordDialog}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <DialogTitle id="modal-modal-title">Change Password</DialogTitle>
                    <Box component="form" onSubmit={handleChangePassword} sx={{display:"flex", flexDirection:"column", padding:5}}>
                        <TextField type="password" label="Old Password" id="oldPassword" name="oldPassword" sx={{ marginTop: 0 }} />
                        <TextField type="password" label="New Password" id="newPassword" name="newPassword" sx={{ marginTop: 4 }} />
                        <TextField type="password" label="Confirm New Password" id="confirmNewPassword" name="confirmNewPassword" sx={{ marginTop: 4 }} />
                        {passwordChangeErrors.map((error) => {
                            return <Typography color="error">{error}</Typography>
                        })}
                    </Box>
                    <DialogActions>
                        <Button variant="contained" color="secondary" type="submit" sx={{marginRight:4}}>Change</Button>
                        <Button variant="contained" onClick={handleCloseChangePasswordDialog}>Cancel</Button>
                    </DialogActions>
            </Dialog>

            <Dialog
                open={openPasswordChangeSuccessDialog}
                onClose={handleClosePasswordChangeSuccessDialog}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <DialogTitle id="modal-modal-title">Password changed successfully</DialogTitle>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={handleClosePasswordChangeSuccessDialog}>Ok</Button>
                    </DialogActions>
            </Dialog>
            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <DialogContent>
                        <Typography variant="h5">Are you sure you want to delete your account?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={handleDelete}>Delete</Button>
                        <Button variant="contained" onClick={handleCloseDeleteDialog}>Cancel</Button>
                    </DialogActions>
            </Dialog>


            <SessionTable/>
            
        </Container>
    )
}


function SessionTable() {

    const [sessions, setSessions] = React.useState([] as Session[]);
    // const [totalPages, setTotalPages] = React.useState(0);
    const [page, setPage] = React.useState(0);

    const [selected, setSelected] = React.useState<readonly string[]>([]);

    useEffect(() => {
        //get sessions for user
        const client = new SessionsClient();
        // if page changed get new sessions
        client.getUserSessions(page).then(result => {
            setSessions(result.sessions!);
            // setTotalPages(result.totalPages!);
        });
    }, [page]);

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const handleClickRow = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    
    };

    const handleDelete = () => {
        const client = new SessionsClient();

        //for each selected session delete it
        selected.forEach((sessionId) => {
            client.deleteId(sessionId).then(() => {
                setSessions(sessions.filter(session => session.id !== sessionId));
            });
        });

        setSelected([]);
    }

    return (
        <Box sx={{margin: "10px 0"}}>
        <Paper>
            <SessionsToolbar numSelected={selected.length} handleDelete={handleDelete}/>
            <TableContainer>
                <Table size="small">
                    <SessionsTable numSelected={selected.length} onSelectAllClick={(event) => {
                        if (event.target.checked) {
                            const newSelecteds = sessions.map((n) => n.id!);
                            setSelected(newSelecteds);
                            return;
                        }
                        setSelected([]);
                    }} rowCount={sessions.length} />

                    <TableBody>
                        {sessions.map(session => {
                            const isItemSelected = isSelected(session.id!);

                            return (
                                <TableRow key={session.id} selected={isItemSelected}
                                    onClick={(event) => handleClickRow(event, session.id!)}
                                    hover
                                    role="checkbox"
                                >
                                    <TableCell>
                                        <Checkbox
                                            checked={isItemSelected}
                                            inputProps={{ 'aria-labelledby': session.id! }}
                                        />
                                    </TableCell>
                                    <TableCell>{getSessionNameFromType(session.sessionType!)}</TableCell>
                                    <TableCell>{formatDateTime(session.sessionStartTime!)}</TableCell>
                                    <TableCell><TrackInfoComponent track={session.trackId!}/></TableCell>
                                    <TableCell>{session.isMultiplayer ? "Multiplayer" : "Singleplayer"}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={-1}
                rowsPerPage={10}
                page={page}
                onPageChange={(event, newPage) => {
                    setPage(newPage);
                }}
                onRowsPerPageChange={(event) => {
                    setPage(0);
                }}
            />
        </Paper>
        </Box>
    )
}

interface SessionsTableProps {
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
}

function SessionsTable(props: SessionsTableProps) {
    const { numSelected, onSelectAllClick , rowCount} = props;

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                <TableCell>Session Type</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Track</TableCell>
                <TableCell>Multiplayer</TableCell>
            </TableRow>
        </TableHead>
    );
}


interface SessionsToolbarProps {
    numSelected: number;
    handleDelete: () => void;
}

function SessionsToolbar(props: SessionsToolbarProps) {
    const { numSelected, handleDelete } = props;

    return (
        <Toolbar>
            {numSelected > 0 ? (
                <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                    Sessions
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                <IconButton
                    onClick={handleDelete}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : null}
        </Toolbar>
    );
}