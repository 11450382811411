import { Typography, Link, Container } from "@mui/material"


export default function PrivacyComponent() {
    return (
        <Container>
        <Typography variant="h1">Datenschutzerklärung</Typography>
        <Typography variant="body1">Wie wir mit Ihren personenbezogenen Daten umgehen, erläutern wir Ihnen in dieser Datenschutzerklärung. Maßgabe ist das geltende Datenschutzrecht, insbesondere die Datenschutz-Grundverordnung (DSGVO). Mit Ausnahme der Dienstleister und Drittanbieter, die wir in dieser Datenschutzerklärung benennen, geben wir keine Daten an Dritte weiter. Wenn Sie Fragen haben, sprechen Sie uns gerne an.</Typography>
        <Typography variant="h2">Inhalt</Typography>
        <ul>
           <li className="first">    <Link color="#fff" href="#verantwortlicher">Verantwortlicher</Link>  </li>
           <li>    <Link color="#fff" href="#allgemeine-informationen">Allgemeine Informationen</Link>  </li>
           <li>    <Link color="#fff" href="#datenverarbeitung-beim-aufruf-der-webseite">Datenverarbeitung beim Aufruf der Webseite</Link>  </li>
           <li>    <Link color="#fff" href="#cookies-zaehlpixel-und-mobile-identifier">Cookies, Zählpixel und Mobile Identifier</Link>  </li>
           <li>    <Link color="#fff" href="#kontaktaufnahme">Kontaktaufnahme</Link>  </li>
           <li>    <Link color="#fff" href="#registrierung-fuer-ein-benutzerkonto">Registrierung für ein Benutzerkonto</Link>  </li>
           <li>    <Link color="#fff" href="#weitere-drittdienste">Weitere Drittdienste</Link>  </li>
           <li className="last">    <Link color="#fff" href="#rechte-betroffener-personen">Rechte betroffener Personen</Link>  </li>
        </ul>
        <Typography variant="h2" id="verantwortlicher">Verantwortlicher</Typography>
        <Typography variant="body1">Verantwortlich für die Datenverarbeitung ist</Typography>
        <Typography variant="body1">Oliver Traub<br/>Gothaer Str 12<br/>90522&nbsp;Oberasbach</Typography>
        <Typography variant="h2" id="allgemeine-informationen">Allgemeine Informationen</Typography>
        <div><strong>Bereitstellung von Daten<br/></strong><br/></div>
        <div>Für eine Nutzung unserer Webseite ist in der Regel weder gesetzlich noch vertraglich vorgeschrieben, personenbezogene Daten zur Verfügung stellen. Soweit eine Bereitstellung von Daten für einen Vertragsabschluss erforderlich ist oder der Nutzer dazu verpflichtet ist, personenbezogene Daten bereitzustellen, teilen wir diesen Umstand und welche Folgen die Nichtbereitstellung hätte in dieser Datenschutzerklärung mit.<br/><br/></div>
        <div><strong>Datenübermittlung an Drittländer</strong><br/><br/></div>
        <div>Es ist möglich, dass wir Dienstleister und Drittanbieter einsetzen, die in Ländern außerhalb der Europäischen Union und des Europäischen Wirtschaftsraums ansässig sind. Die Übermittlung personenbezogener Daten in solche Drittländer erfolgt, sofern nicht eine Einwilligung des Nutzers in die Datenübermittlung vorliegt, auf der Grundlage eines Angemessenheitsbeschlusses der Europäischen Kommission (Art.&nbsp;45 DSGVO) oder wir haben geeignete Garantien vorgesehen, um den Datenschutz sicherzustellen (Art.&nbsp;46 DSGVO). Soweit ein Angemessenheitsbeschluss der Europäischen Kommission für die Datenübermittlung in ein Drittland vorhanden ist, weisen wir in dieser Datenschutzerklärung darauf hin. Im Übrigen gilt, dass Nutzer eine Kopie der geeigneten Garantien, soweit diese nicht bereits in den Datenschutzerklärungen der Dienstleister oder Drittanbieter enthalten ist, über uns beziehen können.<br/><br/></div>
        <div><strong>Automatisierte Entscheidungsfindung<br/></strong><br/></div>
        <div>Sollten wir eine automatisierte Entscheidungsfindung einschließlich Profiling vornehmen, informieren wir in dieser Datenschutzerklärung über diesen Umstand, über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung. Im Übrigen findet eine automatisierte Entscheidungsfindung nicht statt.<br/><br/></div>
        <div><strong>Verarbeitung zu anderen Zwecken<br/></strong><br/></div>
        <div>Daten werden grundsätzlich nur zu den Zwecken verarbeitet, für die sie erhoben wurden. Sollen sie ausnahmsweise zu anderen Zwecken weiterverarbeitet werden, informieren wir vor dieser Weiterverarbeitung über diese anderen Zwecke und stellen alle anderen maßgeblichen Informationen zur Verfügung (Art.&nbsp;13 Abs.&nbsp;3 DSGVO).</div>
        <Typography variant="h2" id="datenverarbeitung-beim-aufruf-der-webseite">Datenverarbeitung beim Aufruf der Webseite</Typography>
        <Typography variant="body1">Bei jedem Aufruf unserer Webseite überträgt der Browser des Nutzers verschiedene Daten. Für die Dauer des Besuchs der Webseite werden die folgenden Daten verarbeitet und in Logfiles auch über ein Ende der Verbindung hinaus gespeichert:</Typography>
        <ul>
           <li>Browsertyp und verwendete -version</li>
           <li>Betriebssystem</li>
           <li>Abgerufene Seiten und Dateien</li>
           <li>Übertragene Datenmenge</li>
           <li>Datum und Uhrzeit des Abrufs</li>
           <li>Provider des Nutzers</li>
           <li>IP-Adresse</li>
           <li>Referrer URL</li>
        </ul>
        <Typography variant="body1">Die Verarbeitung dieser Daten ist erforderlich, um die Webseite an den Nutzer ausliefern zu können und für sein Endgerät zu optimieren. Die Speicherung in Logfiles dient der Verbesserung der Sicherheit unserer Webseite (z.B. Schutz vor DDOS-Angriffen).</Typography>
        <Typography variant="body1">Rechtsgrundlage für die Verarbeitung ist Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;f) DSGVO. Unser berechtigtes Interesse besteht in der Bereitstellung der Webseite und der Verbesserung der Webseitensicherheit. Logfiles werden nach 30 Tagen automatisch gelöscht.</Typography>
        <Typography variant="h2" id="cookies-zaehlpixel-und-mobile-identifier">Cookies, Zählpixel und Mobile Identifier</Typography>
        <Typography variant="body1">Auf unserer Webseite setzen wir Technologien ein, um das genutzte Endgerät wiederzuerkennen. Es kann sich dabei um Cookies, Zählpixel und/oder Mobile Identifier handeln. </Typography>
        <Typography variant="body1">Das Wiedererkennen eines Endgeräts kann grundsätzlich zu unterschiedlichen Zwecken erfolgen. Es kann notwendig sein, um Funktionen unserer Webseite bereitzustellen, beispielsweise um einen Warenkorb zur Verfügung zu stellen. Darüber hinaus können die genannten Technologien dazu genutzt werden, um das Verhalten von Nutzern auf der Seite nachzuvollziehen, beispielsweise zu Werbezwecken. Welche Technologien wir im Einzelnen nutzen und zu welchen Zwecken, beschreiben wir in dieser Datenschutzerklärung gesondert.</Typography>
        <Typography variant="body1">Für ein besseres Verständnis erläutern wir nachfolgend allgemein, wie Cookies, Zählpixel und Mobile Identifier funktionieren:</Typography>
        <ul>
           <li>
              <Typography variant="body1">Cookies sind kleine Textdateien, die bestimmte Informationen beinhalten und auf dem Endgerät des Nutzers gespeichert werden. Zumeist handelt es sich um eine Identifikationsnummer, die einem Endgerät zugewiesen wird (Cookie ID).</Typography>
           </li>
           <li>
              <Typography variant="body1">Bei einem Zählpixel handelt es sich um eine transparente Grafikdatei, die auf einer Seite eingebunden wird und eine Logdateianalyse ermöglicht.</Typography>
           </li>
           <li>
              <Typography variant="body1">Ein Mobile Identifier ist eine einzigartige Nummer (Mobile ID), die auf einem mobilen Endgerät gespeichert ist und durch eine Webseite ausgelesen werden kann. </Typography>
           </li>
        </ul>
        <Typography variant="body1">Cookies können erforderlich sein, damit unsere Webseite ordnungsgemäß funktioniert. Rechtsgrundlage für den Einsatz derartiger Cookies ist Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;f) DSGVO. Unser berechtigtes Interesse besteht in der Bereitstellung der Funktionen unserer Webseite.</Typography>
        <Typography variant="body1">Für den Betrieb unserer Webseite nicht erforderliche Cookies setzen wir ein, um unser Angebot nutzerfreundlicher zu machen oder die Nutzung unserer Webseite nachvollziehen zu können. Die Rechtsgrundlage richtet sich hier danach, ob die Einwilligung des Nutzers einzuholen ist oder wir uns auf ein berechtigtes Interesse berufen können. Eine erteilte Einwilligung kann der Nutzer unter anderem durch die Einstellungen in seinem Browser jederzeit widerrufen.</Typography>
        <Typography variant="body1">Der Nutzer kann der Verarbeitung von Daten mithilfe von Cookies durch entsprechende Einstellungen in seinem Browser verhindern und ihr widersprechen. Im Falle des Widerspruchs kann es sein, dass nicht alle Funktionen unserer Webseite zur Verfügung stehen. Über weitere Möglichkeiten des Widerspruchs gegen die Verarbeitung personenbezogener Daten durch Cookies informieren wir in dieser Datenschutzerklärung gesondert. Gegebenenfalls stellen wir Links bereit, mit denen ein Widerspruch erklärt werden kann. Diese sind mit "Opt-Out" beschriftet.</Typography>
        <Typography variant="h2" id="kontaktaufnahme">Kontaktaufnahme</Typography>
        <Typography variant="body1">Im Falle einer Kontaktaufnahme verarbeiten wir die Angaben des Nutzers, Datum und Uhrzeit zum Zwecke der Bearbeitung der Anfrage einschließlich eventueller Rückfragen.</Typography>
        <Typography variant="body1">Rechtsgrundlage für die Datenverarbeitung ist Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;f) DSGVO. Unser berechtigtes Interesse besteht in der Beantwortung der Anfragen unserer Nutzer. Zusätzliche Rechtsgrundlage ist Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;b) DSGVO, wenn die Verarbeitung für die Erfüllung eines Vertrags oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist.</Typography>
        <Typography variant="body1">Die Daten werden gelöscht, sobald die Anfrage einschließlich etwaiger Rückfragen beantwortet ist. Wir überprüfen in regelmäßigen Abständen, mindestens aber alle zwei Jahre, ob im Zusammenhang mit Kontaktaufnahmen angefallene Daten zu löschen sind.</Typography>
        <Typography variant="h2" id="registrierung-fuer-ein-benutzerkonto">Registrierung für ein Benutzerkonto</Typography>
        <Typography variant="body1">Nutzer können sich auf unserer Webseite für unser Angebot registrieren. In diesem Zusammenhang verarbeiten wir die bei der Anmeldung eingegebenen Daten. Die E-Mail-Adresse lassen wir uns durch Zusendung eines Links bestätigen (Double-Opt-In), um einen Missbrauch der Registrierungsfunktion zu verhindern. Weiter verarbeiten wir zu diesem Zweck Datum und Uhrzeit und die IP-Adresse des Nutzers. Zu Nachweiszwecken verarbeiten wir bei einem Klick auf den Bestätigungslink ferner Datum, Uhrzeit und IP-Adresse des Nutzers.</Typography>
        <Typography variant="body1">Die Daten werden mit der Löschung des Benutzeraccounts nach Ablauf von drei Jahren zum Schluss des Jahres gelöscht, wenn nicht eine längere gesetzliche Aufbewahrungspflicht entgegensteht.</Typography>
        <Typography variant="body1">Rechtsgrundlage für die Verarbeitung ist Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;a) DSGVO, soweit wir eine Einwilligung des Nutzers einholen. Ist die Verarbeitung für die Erfüllung eines Vertrags oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, beruht sie auf Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;b) DSGVO. Rechtsgrundlage im Übrigen ist Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;f) DSGVO. Unser berechtigtes Interesse besteht darin, den Nutzern einen Zugang zu unserem registrierungspflichtigen Angebot zu ermöglichen, uns vor einem Missbrauch der Registrierungsfunktion zu schützen und eine ordnungsgemäße Eintragung nachweisen zu können. Nach der Löschung des Benutzeraccounts besteht unser berechtigtes Interesse überdies in der Abwehr möglicher Ansprüche.</Typography>
        <Typography variant="h2" id="weitere-drittdienste">Weitere Drittdienste</Typography>
        <Typography variant="body1"> </Typography>
        <Typography variant="body1"><strong>Google Analytics</strong></Typography>
        <Typography variant="body1">Zu Analyse der Nutzung unserer Webseite setzen wir Google Analytics ein. Anbieter: Google Ireland Ltd., Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland.</Typography>
        <Typography variant="body1">Um Aktivitäten des Nutzers auf der Webseite nachverfolgen zu können, wird ein Cookie auf dem Endgerät gesetzt. Wir nutzen Google Analytics mit der Erweiterung <em>anonymize IP</em>. Die IP-Adresse des Nutzers wird automatisch gekürzt, bevor sie an Server in den USA übertragen wird. Ausgewertet werden unter anderem der ungefähre geografische Standort, Endgerät, Bildschirmauflösung, Browser sowie besuchte Seiten einschließlich der Verweildauer.</Typography>
        <Typography variant="body1">Soweit wir eine Einwilligung des Nutzers einholen, erfolgt die Verarbeitung von Daten auf der Rechtsgrundlage des Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;a) DSGVO. Im Übrigen beruht sie auf Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;f) DSGVO. Unser berechtigtes Interesse besteht in der Optimierung unserer Webseite, der Verbesserung unserer Angebote und dem Online-Marketing.</Typography>
        <Typography variant="body1">Die durch Google Analytics gesammelten Daten werden nach Ablauf von 14 Monaten automatisch gelöscht.</Typography>
        <Typography variant="body1"><Link color="#fff" href="https://tools.google.com/dlpage/gaoptout?hl=de">Opt-Out</Link></Typography>
        <Typography variant="body1"><Link color="#fff" href="https://policies.google.com/privacy?hl=de">Datenschutzerklärung von Google Analytics</Link></Typography>
        <div><strong>Google Adsense</strong></div>
        <div>Wir nutzen Google Adsense. Anbieter: Google Ireland Ltd., Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland.</div>
        <div>Über Google Adsense schalten wir personalisierte Werbeanzeigen. Google nutzt Cookies und Zählpixel, um das Verhalten von Nutzern zu analysieren und passende Werbung auswählen zu können. Dabei werden unter anderem die Aufrufe unserer Webseite und anderer Webseiten, die Google Adsense verwenden, ausgewertet und einer Nutzer-ID zugeordnet. Die Daten werden nicht mit anderen von Google gespeicherten Nutzerdaten zusammengeführt.</div>
        <div>Soweit wir eine Einwilligung des Nutzers einholen, erfolgt die Verarbeitung auf der Rechtsgrundlage des Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;a) DSGVO. Im Übrigen beruht sie auf Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;f) DSGVO. Unser berechtigtes Interesse besteht in der Auslieferung von interessenbasierter Werbung.</div>
        <div>Nutzer können der Verwendung von Daten durch Google für personalisierte Werbung mit dem nachfolgenden Opt-Out jederzeit widersprechen.</div>
        <Typography variant="body1"><Link color="#fff" href="https://adssettings.google.com/">Opt-Out</Link></Typography>
        <Typography variant="body1"><Link color="#fff" href="https://policies.google.com/privacy?hl=de">Datenschutzerklärung von Google Adsense</Link></Typography>
        <Typography variant="h2" id="rechte-betroffener-personen">Rechte betroffener Personen</Typography>
        <Typography variant="body1">Werden personenbezogene Daten des Nutzers verarbeitet, ist er betroffene Person im Sinne der DSGVO. Betroffenen Personen stehen die folgenden Rechte zu:</Typography>
        <Typography variant="body1"><strong>Recht auf Auskunft:</strong> Die betroffene Person hat das Recht, eine Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden. Werden personenbezogene Daten verarbeitet, so hat die betroffene Person ein Recht auf unentgeltliche Auskunft sowie auf eine Kopie der personenbezogenen Daten, die Gegenstand der Verarbeitung sind.</Typography>
        <Typography variant="body1"><strong>Recht auf Berichtigung:</strong> Die betroffene Person hat das Recht, die unverzügliche Berichtigung unrichtiger oder Vervollständigung unvollständiger personenbezogener Daten zu verlangen.</Typography>
        <Typography variant="body1"><strong>Recht auf Löschung:</strong> Die betroffene Person hat das Recht, nach Maßgabe der gesetzlichen Bestimmungen eine unverzügliche Löschung sie betreffender personenbezogener Daten zu verlangen.</Typography>
        <Typography variant="body1"><strong>Recht auf Einschränkung der Verarbeitung:</strong> Die betroffene Person hat das Recht, nach Maßgabe der gesetzlichen Bestimmungen eine Einschränkung der Verarbeitung sie betreffender personenbezogener Daten zu verlangen.</Typography>
        <Typography variant="body1"><strong>Recht auf Datenübertragbarkeit:</strong> Die betroffene Person hat das Recht, die sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder eine Übermittlung an einen anderen Verantwortlichen zu verlangen.</Typography>
        <Typography variant="body1"><strong>Recht auf Widerspruch:</strong> Die betroffene Person hat das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender personenbezogener Daten, die aufgrund von Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;e) oder f) DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Werden personenbezogene Daten verarbeitet, um Direktwerbung zu betreiben, so hat die betroffene Person das Recht, jederzeit Widerspruch gegen die Verarbeitung sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</Typography>
        <Typography variant="body1"><strong>Recht auf Widerruf:</strong> Die betroffene Person hat das Recht, ihre erteilte Einwilligung jederzeit zu widerrufen.</Typography>
        <Typography variant="body1"><strong>Recht auf Beschwerde:</strong> Die betroffene Person hat das Recht, sich bei einer Aufsichtsbehörde zu beschweren.</Typography>
        <Typography variant="body1"><strong>Stand der Datenschutzerklärung:</strong> 26. April 2024</Typography>
        <Typography variant="body1">Diese Datenschutzerklärung wurde mit dem Generator von <Link color="#fff" href="https://www.einfach-abmahnsicher.de">Einfach Abmahnsicher</Link> in Kooperation mit <Link color="#fff" href="https://www.prigge-recht.de">PRIGGE Recht</Link> erstellt.</Typography>
    </Container>
                );
}