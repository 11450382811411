

export default interface Telemetry{
    timestamp: number;

    speed: number;
    throttle: number;
    brake: number;
    steering: number;

    gear: number;
    rpm: number;

    wheels: Array<Wheel>;
}


interface SessionState{

    sessionType: number;
    id: number;

    drivers: Array<Driver>;

    raceCondition: string;

    weather: Array<Weather>;

    trackId: number;
    totalLaps: number;

    sessionTimeLeft: number;
    sessionDuration: number;

    trackTemp: number;
    airTemp: number;

    safetyCarStatus: number;

    // TODO: add events
    // events: Array<SessionEvent>;

}


interface Driver{
    bestLapTimeMs: number;
    driverInfo: DriverInfo;

    name: string;
    carNumber: number;
    team: number;
    position: number;

    lapTimes: Array<LapTimeInfo>;

    worldPosition: Vector3;

    currentLap: number;
    lapTime: number;
    lapDistance: number;
    lapDelta: number;

    lapTimeHistory: Array<LapTime>;

    fastestLapNumber: number;
    sector1Time: number;
    sector2Time: number;
    sector3Time: number;

    pitStop: number;

    tyres: Array<Wheel>;
    tyreType: number;
    tyreAge: number;

    gridPosition: number;
    finalPosition: number;
    totalRaceTime: number;

    finishState: number;

    gapAhead: number;
    startingPosition: number;
    finishingPosition: number;

    totalPenaltiesSeconds: number;

    totalWarnings: number;
    warnings: Array<Warning>;
    penalties: Array<Penalty>;
    // TODO: add penalties
    // penalties: Array<Penalty>;
}

interface Warning{
    warningType: number;
    lapNum: number;
    warningTime: number;

}

interface Penalty{
    penaltyType: number;
    lapNum: number;
    penaltyTime: number;
    infringementType: number;
    otherVehicleIdx: number;
}

interface LapTimeInfo{
    lapTime: number;
    invalidLap: boolean;
    sector1Time: number;
    sector2Time: number;
    sector3Time: number;
}

interface DriverInfo{
    name: string;
    teamId: number;
    carNumber: number;
    nationalityId: number;
    networkId: number;
}


interface Wheel{
    tyreType: number;
    tyreAge: number;
    innerTemp: number;
    surfaceTemp: number;
    pressure: number;
    brakeTemp: number;
}

interface Weather{

    forecastedWeatherCondition: number;
    timeOffSet: number;

    trackTemperatureCelsius: number;
    trackTemperatureChange: number;
    rainPercentage: number;
    airTemperatureCelsius: number;
    airTemperatureChange: number;
    sessionTypeMode: number;

}

export interface LapTime{
    lapNumber: number;

    lapTime: number;
    sector1Time: number;
    sector2Time: number;
    sector3Time: number;
    lapSteps: Array<any>;

    carPosition: number;
}


interface LapTelemetry{
    lapDistance: number;
    lapTime: number;

    throttle?: number;
    brake?: number;
    steering?: number;

    gear?: number;
    rpm?: number;

    worldPosition?: Vector3;
}


interface Vector3{
    x: number;
    y: number;
    z: number;
}


enum Track {
    Melbourne = 0,
    PaulRicard = 1,
    Shanghai = 2,
    Sakhir = 3, //Bahrain
    Catalunya = 4,
    Monaco = 5,
    Montreal = 6,
    Silverstone = 7,
    Hockenheim = 8,
    Hungaroring = 9,
    Spa = 10,
    Monza = 11,
    Singapore = 12,
    Suzuka = 13,
    AbuDhabi = 14,
    Texas = 15,
    Brazil = 16,
    Austria = 17,
    Sochi = 18,
    Mexico = 19,
    Baku = 20,
    SakhirShort = 21,
    SilverstoneShort = 22,
    TexasShort = 23,
    SuzukaShort = 24,
    Hanoi = 25,
    Zandvoort = 26,
    Imola = 27, //New to F1 2021
    Portimao = 28, //New to F1 2021
    Jeddah = 29, //New to F1 2021
    Miami = 30, //New to F1 22
    LasVegs = 31, //New to F1 22
    Qatar = 32 //New to F1 22
}


enum SessionType
        {
            Unknown = 0,
            P1 = 1,
            P2 = 2,
            P3 = 3,
            ShortPractice = 4,
            Q1 = 5,
            Q2 = 6,
            Q3 = 7,
            ShortQualifying = 8,
            OneShotQualifying = 9,
            Race = 10,
            Race2 = 11,
            TimeTrial = 12
        }

export type { Telemetry, Wheel, Weather, Vector3, Driver, DriverInfo, SessionState, LapTelemetry, LapTimeInfo };
export { Track, SessionType };