import { Component } from "react";
import cx from "classnames";
import "./team.scss"
import "./leaderboard.scss"

export class DriverComponent extends Component<{ driverName: string, carNumber: number, teamId: number, position:number }> {

    getTeamClass(teamId: number) {
        switch (teamId) {
            case 0:
                return "team-mercedes";
            case 1:
                return "team-ferrari";
            case 2:
                return "team-redbull";
            case 3:
                return "team-williams";
            case 4:
                return "team-aston-martin";
            case 5:
                return "team-alpine";
            case 6:
                return "team-alpha";
            case 7:
                return "team-haas";
            case 8:
                return "team-mclaren";
            case 9:
                return "team-sauber";

            default:
                return "team";
        }
    }

    getDriverShortName() {
        return this.props.driverName?.substring(0, 3).toUpperCase()+"#"+this.props.carNumber;
    }

    render(){

        let teamClass = this.getTeamClass(this.props.teamId);

        return (<div className={cx(teamClass, "driverInfo")}>
            <div className="position">{this.props.position}</div>
            <div className="driverName">{this.getDriverShortName()}</div>
        </div>);
    }
}