import { Container, Link, Typography } from "@mui/material"


export default function ImpressumComponent() {

    return (<div>
        <Container>
            <Typography variant="h2">Impressum</Typography>
            <Typography variant="h3">Angaben gemäß § 5 TMG</Typography>
            <Typography paragraph >
                Oliver Traub <br/>
                Gothaer Straße 12<br/>
                90522 Oberasbach
            </Typography>
            <Typography variant="h3">Kontakt</Typography>
            <Typography paragraph>

                E-Mail: <Link color="inherit" href="mailto:contact@pitwallcompanion.com">contact@pitwallcompanion.com</Link>
            </Typography>
        </Container>
    </div>
    );
}