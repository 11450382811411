import { useState, useEffect, useLayoutEffect, useRef, useCallback } from "react";


export const useDimensions = (targetRef: React.RefObject<HTMLDivElement>) => {

    const getDimensions = useCallback(() => {
      return {
        width: targetRef.current ? targetRef.current.offsetWidth : 0,
        height: targetRef.current ? targetRef.current.offsetHeight : 0
      };
    }, [targetRef]);
  
    const [dimensions, setDimensions] = useState(getDimensions);
  
    const handleResize = useCallback(() => {
      setDimensions(getDimensions());
    }, [getDimensions]);
  
    useEffect(() => {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);
  
    useLayoutEffect(() => {
      handleResize();
    }, [handleResize]);
  
    return dimensions;
  }


export const useChartDimensions = (passedSettings:ChartDimensions): [React.RefObject<any>, ChartDimensions] => {
    const ref = useRef()
    const dimensions = combineChartDimensions(
        passedSettings
    )
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    // @ts-ignore
    useEffect(() => {
            if (dimensions.width && dimensions.height)
                return [ref, dimensions]
            const element = ref.current
            const resizeObserver = new ResizeObserver(
                entries => {
                    if (!Array.isArray(entries)) return
                    if (!entries.length) return
                    const entry = entries[0]
                    if (width !== entry.contentRect.width)
                        setWidth(entry.contentRect.width)
                    if (height !== entry.contentRect.height)
                        setHeight(entry.contentRect.height)
                }
            )
            try {
              
              // @ts-ignore
                resizeObserver.observe(element)
            } catch (e) {
                console.error(e);
            }
            return () => {
              try {
                // @ts-ignore
                resizeObserver.unobserve(element)
              } catch (e) {
                console.error(e)
              }
            }
    }, [dimensions, width, height])
    const newSettings = combineChartDimensions({
            ...dimensions,
            width: dimensions.width || width,
            height: dimensions.height || height,
    })
    return [ref, newSettings]
}


export interface ChartDimensions  {
    marginTop?: number;
    marginRight?: number;
    marginBottom?: number;
    marginLeft?: number;
    width?: number;
    height?: number;
    boundedHeight?: number;
    boundedWidth?: number;
}

  const combineChartDimensions = (dimensions: ChartDimensions) => {
    const parsedDimensions = {
        ...dimensions,
        marginTop: dimensions.marginTop || 10,
        marginRight: dimensions.marginRight || 10,
        marginBottom: dimensions.marginBottom || 40,
        marginLeft: dimensions.marginLeft || 75,
    }

    // console.log("parsedDimensions", parsedDimensions);
    return {
        ...parsedDimensions,
        boundedHeight: Math.max(
          parsedDimensions.height!
          - parsedDimensions.marginTop
          - parsedDimensions.marginBottom,
          0,
        ),
        boundedWidth: Math.max(
          parsedDimensions.width!
          - parsedDimensions.marginLeft
          - parsedDimensions.marginRight,
          0,
        ),
    }
  }