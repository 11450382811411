import React, { Component } from "react";
import { LapTime } from "./LapTimeComponent";
import { SectorTime } from "./SectorTimesComponent";
import "./leaderboard.scss";
import { TyreInfoComponent } from "./TyreInfoComponent";
import { LapTimeInfoComponent } from "./LapTimesInfoComponen";
import { DriverComponent } from "./DriverComponent";
import { ResultStatus, SessionDriver } from "../ApiClient";
import { Flipped, Flipper } from "react-flip-toolkit";
import { Box } from "@mui/material";
import { getResultStatusString } from "../shared/utils";

type LeaderboardProps = {
    drivers: SessionDriver[];
}

export class RaceLeaderboardComponent extends Component<LeaderboardProps> {


    render() {

        if (this.props.drivers === undefined || this.props.drivers === null || this.props.drivers.length === 0) return (<div></div>);


        let bestLapTimes = this.props.drivers.map(d => Math.min(...d.lapTimes!.slice(0, d.lapTimes!.length - 1).map(l => l.lapTime!)));
        let bestTime = Math.min(...bestLapTimes);
        // let bestTimeIdx = this.props.drivers.findIndex(d => d.bestLapTimeMs === bestTime);

        const driverIndices = this.props.drivers.map((d, idx) => ({idx: idx, value: d}));
        const sortedDriverIndices = driverIndices.sort((x, y) => x.value.position! - y.value.position!);

        let sortedDrivers = this.props.drivers.sort((x, y) => x.position! - y.position!);

        let bestSector1Time = Math.min(...sortedDrivers.map(d => d.bestSector1Time!));
        let bestSector2Time = Math.min(...sortedDrivers.map(d => d.bestSector2Time!));
        let bestSector3Time = Math.min(...sortedDrivers.map(d => d.bestSector3Time!));

        const moreThan20Drivers = sortedDrivers.length > 20;
        return (
            <div className="leaderboardContainer">
                <div className="drivers">
                    <Flipper flipKey={sortedDrivers.map(d => d.carNumber).join("")}>
                    <ul className={moreThan20Drivers ? "driversGridEx": "driversGrid"}>
                        {sortedDriverIndices.map((item, idx) => {
                            let driver = item.value;
                            let lastLapTimesBeforeCurrent  = driver.lapTimes!.slice(0, driver.lapTimes!.length - 1);
                            let bestDriverTime = Math.min(...lastLapTimesBeforeCurrent!.map(l => l.lapTime!));

                            let penaltiesElement = driver.totalPenaltiesSeconds! > 0 ? <Box sx={{color:"red"}}>+{driver.totalPenaltiesSeconds}s</Box> : <div></div>;

                            //get next multiple of 3 larger than the number of warnings
                            let nextMultipleOfThree = Math.ceil((driver.totalCornerCutWarnings!+1) / 3) * 3;

                            let cornerCutWarningsElement = <Box sx={{color:"orange"}}>{driver.totalCornerCutWarnings}/{nextMultipleOfThree}</Box>;

                            let resultStatus = driver.resultStatus;

                            let isRetired = resultStatus === ResultStatus.Retired || resultStatus === ResultStatus.Disqualified || resultStatus === ResultStatus.DidNotFinish;
                            return (
                            <Flipped flipId={item.idx} key={item.idx}>
                            <li className="driver">
                                <DriverComponent carNumber={driver.carNumber!} teamId={driver.team!} driverName={driver.name!} position={driver.position!}></DriverComponent>
                                <div>
                                    <LapTimeInfoComponent times={
                                    {
                                        bestLapTime: bestDriverTime,
                                        lastLapTime: driver.lapTimes!.length > 1 ? driver.lapTimes![driver.lapTimes!.length - 2].lapTime! : 0,
                                    }
                                } hasBestLap={bestDriverTime === 0 ? false: (bestDriverTime === bestTime)}/>
                                </div>
                                
                                { isRetired ? <Box sx={{width:"100%", textAlign:"center"}}>{getResultStatusString(resultStatus!)}</Box> : <Box sx={{display:"flex"}}>
                                <div>
                                    <div>
                                        <div className="intervalGap">
                                            <div>Int </div>
                                            <LapTime time={driver.deltaToCarAhead!} showMinutes={false} />
                                        </div>
                                    </div>
                                </div>
                                <SectorTime currentSector1Time={driver.currentSector1Time!} currentSector2Time={driver.currentSector2Time!} currentSector3Time={driver.currentSector3Time!}
                                    lastSector1Time={driver.lastSector1Time!} lastSector2Time={driver.lastSector2Time!} lastSector3Time={driver.lastSector3Time!} bestSector1Time={driver.bestSector1Time!}
                                    bestSector2Time={driver.bestSector2Time!} bestSector3Time={driver.bestSector3Time!} totalBestSector1Time={bestSector1Time} totalBestSector2Time={bestSector2Time} totalBestSector3Time={bestSector3Time} 
                                    />
                                </Box>
                                }
                                <div><TyreInfoComponent tyreAgeLaps={driver.currentTyreAgeLaps!} tyreType={driver.currentTyreCompound!} /></div>
                                <div>{cornerCutWarningsElement}</div>
                                <div> {penaltiesElement}</div>
                            </li>
                            </Flipped>)

                        })}
                    </ul>
                    </Flipper>
                </div>
            </div>
        )
    }

}