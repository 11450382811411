import React from "react";
import { RoutedProps } from "../App";
import { LiveSessionClient, Session } from "../ApiClient";
import { Box, Container, Typography } from "@mui/material";
import { LiveSessionInternalComponent } from "./LiveSessionComponent";

type LiveSessionState = {
    session: Session,
    sessionId: string
}

export class LiveUserSessionComponent extends React.Component<RoutedProps, LiveSessionState> {
    updateInterval!: NodeJS.Timer;
    socket?: WebSocket;

    constructor(props: RoutedProps) {
        super(props);
        this.state = {
            session: new Session(),
            sessionId: ""
        };

    }


    componentDidMount() {

        document.title = "Pitwall Companion - Live Session";

        const client = new LiveSessionClient();

        client.getUserSession(this.props.location.pathname.split("/")[2]).then(session => {
            this.setState({ session: session });
        });
        this.setState({ sessionId: this.props.location.pathname.split("/")[2] });
        const id = this.props.location.pathname.split("/")[2];
        const scheme = document.location.protocol === "https:" ? "wss" : "ws";
        const port = document.location.port ? (":" + document.location.port) : "";
        // console.log("Connecting to " + scheme + "://" + document.location.hostname + port + "/api/livesession/ws/"+ this.state.sessionId);
        const connectionUrl = scheme + "://" + document.location.hostname + port + "/api/livesession/ws/user/"+ id ;
        this.socket = new WebSocket(connectionUrl);

        this.socket.onopen = () => {
            // setup heartbeat
            const sendingInterval = setInterval(() => {
                try {
                    this.socket?.send(JSON.stringify({
                        type: 'heartbeat'
                    }));
                } catch (e: any) {
                    console.error('Error sending heartbeat:', e);
                    if (e instanceof DOMException && e.name === 'InvalidStateError') {
                        clearInterval(sendingInterval);
                    }
                }
            }, 10000);
        };

        this.socket.onmessage = (event) => {
            const data = JSON.parse(event.data);

            if (data.type === 'session') {
                this.setState({
                    session: data.data
                });
            }
        };



        this.socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };
    }

    componentWillUnmount() {
        // clearInterval(this.updateInterval);
        this.socket?.close(4154, "User closed the page");
    }

    render() {
        if (!this.state.session || !this.state.session.drivers) {
            return (<Container maxWidth="md" sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "90vh" }}>
                    <Box>
                        <Typography variant="h3">The user doesn't seem to be in a session at the moment.</Typography>
                        
                    </Box>
                </Container>)
        }
        return <LiveSessionInternalComponent session={this.state.session}/>
    }
}