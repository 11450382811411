import React from "react";
import * as d3 from "d3";
import { SessionDriver } from "../ApiClient";
import { abudhabi, australi, austria, azerbaijan, bahrain, belgium, brazil, canada, china, france, greatbritain, hungary, italy, japan, mexico, monaco, netherlands, russia, singapore, spain, usa, vietnam } from "./TrackImages";
import { Paper } from "@mui/material";


interface TrackMapProps {
    trackId: number,
     drivers: SessionDriver[],
    width: number,
    height: number,
    trackLength: number,
}

const trackSizeDict: { [key: number]: { xMax: number, xMin: number, zMax: number, zMin: number, aspectRatio:number } } = {
    0: { xMax: 739, xMin: -730, zMax: 872, zMin: -862, aspectRatio: 806/957},
    1: { xMax: 1042, xMin: -1071, zMax: 571, zMin: -653, aspectRatio: 1744/1014},
    2: { xMax: 608, xMin: -612, zMax: 540, zMin: -533, aspectRatio: 1043/908},
    3: { xMax: 398, xMin: -410, zMax: 592, zMin: -596, aspectRatio: 688/1004},
    5: { xMax: 395, xMin: -351, zMax: 504, zMin: -452, aspectRatio: 753/958},
    4: { xMax: 439, xMin: -528, zMax: 552, zMin: -598, aspectRatio: 854/1011},
    6: { xMax: 428, xMin: -192, zMax: 1472, zMin: -439, aspectRatio: 369/1022},
    7: { xMax: 396, xMin: -606, zMax: 976, zMin: -746, aspectRatio: 587/995},
    9: { xMax: 459, xMin: -598, zMax: 587, zMin: -612, aspectRatio: 901/1016},
    10: { xMax: 542, xMin: -722, zMax: 973, zMin: -1053, aspectRatio: 628/1000},
    11: { xMax: 626, xMin: -626, zMax: 1081, zMin: -1082, aspectRatio: 629/1031},
    13: { xMax: 994, xMin: -990, zMax: 490, zMin: -498, aspectRatio: 1668/839},
    15: { xMax: 983, xMin: -814, zMax: 1026, zMin: -37, aspectRatio: 1510/900},
    16: { xMax: 93, xMin: -558, zMax: 694, zMin: -338 , aspectRatio: 639/986},
    17: { xMax: 731, xMin: -531, zMax: 303, zMin: -487 , aspectRatio: 1279/801},
    19: { xMax: 508, xMin: -1019, zMax: 60, zMin: -1021 , aspectRatio: 1336/955},
    20: { xMax: 885, xMin: -1180, zMax: 587, zMin: -889, aspectRatio: 1358/976},
    26: { xMax: 499, xMin: -464, zMax: 426, zMin: -406, aspectRatio: 983/852},
}


export class TrackMapComponent extends React.Component<TrackMapProps, {xMax: number,
    xMin: number,
    zMax: number,
    zMin: number,
}> {
    containerRef!: HTMLDivElement | null;

    static defaultProps = {
        width: 1000,
        height: 1000,
    }

    teamColors = d3.scaleOrdinal().range([ "#00a19c", "#a6051a", "#121f45", "#041E42", "#00352f", "#005ba9", "#00293f", "#f9f2f2", "#ff8000", "#981E32"]);
    colors = ["#00a19c", "#a6051a", "#121f45", "#041E42", "#00352f", "#005ba9", "#00293f", "#f9f2f2", "#ff8000", "#981E32"];
    color = (x:number) => this.colors[x];
    getImageForTrackId(trackId: number) {
        switch (trackId) {
            case 0:
                return australi;
            case 1:
                return france;
            case 2:
                return china;
            case 3:
                return bahrain;
            case 4:
                return spain;
            case 5:
                return monaco
            case 6:
                return canada;
            case 7:
                return greatbritain;
            // case 8:
            //     return germany;
            case 9:
                return hungary;
            case 10:
                return belgium;
            case 11:
                return italy;
            case 12:
                return singapore;
            case 13:
                return japan;
            case 14:
                return abudhabi;
            case 15:
                return usa;
            case 16:
                return brazil;
            case 17:
                return austria;
            case 18:
                return russia;
            case 19:
                return mexico;
            case 20:
                return azerbaijan;
            case 25:
                return vietnam;
            case 26:
                return netherlands;
            // case 27:
                // return imola;
            default:
                return mexico;
        }
    }

    constructor(props: TrackMapProps) {
        super(props);
        this.state = {
            xMax: 0,
            xMin: 0,
            zMax: 0,
            zMin: 0,
        };

    }

    render() {
        // calculate min and max values for x and z of the drivers live positions
        let xMax = Math.max(...this.props.drivers.map((d) => d.livePosition?.x!));
        let xMin = Math.min(...this.props.drivers.map((d) => d.livePosition?.x!));
        let zMax = Math.max(...this.props.drivers.map((d) => d.livePosition?.z!));
        let zMin = Math.min(...this.props.drivers.map((d) => d.livePosition?.z!));

        // set the state with the min and max values if they are smaller or bigger than the current state
        if (xMax > this.state.xMax) {
            this.setState({ xMax: xMax });
        }
        if (xMin < this.state.xMin) {
            this.setState({ xMin: xMin });
        }
        if (zMax > this.state.zMax) {
            this.setState({ zMax: zMax });
        }
        if (zMin < this.state.zMin) {
            this.setState({ zMin: zMin });
        }

        // console.log(this.state.xMax, this.state.xMin, this.state.zMax, this.state.zMin);

        // return <img className="trackMap" src={trackImage} alt="Track" />;
        return (
            <Paper style={{flex: "1 1 auto", display:"flex", flexDirection:"column", minHeight: "300px", marginTop: "10px"}}>
            {/* <div> xMax: {this.state.xMax}  xMin: {this.state.xMin} zMax: {this.state.zMax} zMin: {this.state.zMin}</div> */}
            <div style={{flex:"2 1 auto", width:"100%"}}   ref={ref => this.containerRef = ref}></div>
        
        </Paper>)
    }

    componentDidUpdate() {
        
        
        d3.select(this.containerRef).selectAll("*").remove();

        // get height of the container
        // let height = Math.min(this.containerRef?.clientWidth!, this.containerRef?.clientHeight!);
        let height = this.containerRef?.clientHeight!;
        let width = this.containerRef?.clientWidth!;

        const canvas = d3.select(this.containerRef).append("svg")
            .attr("width", width)
            .attr("height", height)

        let margin = 50;
        let widthNumber = width - margin;
        const heightNumber = height - margin;


        //check if the trackId is in the dictionary
        if (!trackSizeDict[this.props.trackId]) {
            //console.log("Track not found in dictionary");
            // canvas.append("text")
            //     .text("Track map not yet available for this track")
            //     .attr("x", this.props.width / 2)
            //     .attr("y", this.props.height / 2)
            //     .style("font-size", "20px")
            //     .style("font-weight", "bold")
            //     .style("text-anchor", "middle")
            //     .style("color", "white")

            margin = 80;

            // draw circle of doom if track is not found
            
            const radius = (Math.min(width,height) - margin) / 2;
            canvas.append("circle")
                .attr("cx", width /2)
                .attr("cy", height/ 2)
                .attr("r", radius)
                .style("fill", "transparent")
                .style("stroke", "white")
                .style("stroke-width", "10px")
                // add border around the circle

            // add line marker for the start/finish line at the top of the circle
            canvas.append("line")
                .attr("x1", width / 2)
                .attr("y1", (height/2 -radius) -20)
                .attr("x2", width / 2)
                .attr("y2",  (height/2 -radius)+20)
                .style("stroke", "white")
                .style("stroke-width", "5px")

            // add the checkered flag pattern
            const pattern =  canvas.append("defs")
                .append("pattern")
                .attr("id", "checkered")
                .attr("x",0)
                .attr("y",0)
                .attr("width", 20)
                .attr("height", 20)
                .attr("patternUnits", "userSpaceOnUse")

            pattern.append("rect")
                .attr("width", 10)
                .attr("height", 10)
                .attr("fill", "white")

            pattern.append("rect")
                .attr("width", 10)
                .attr("height", 10)
                .attr("x", 10)
                .attr("y", 0)
                .attr("fill", "black")

            pattern.append("rect")
                .attr("width", 10)
                .attr("height", 10)
                .attr("x", 0)
                .attr("y", 10)
                .attr("fill", "black")

            pattern.append("rect")
                .attr("width", 10)
                .attr("height", 10)
                .attr("x", 10)
                .attr("y", 10)
                .attr("fill", "white")
        

            // draw checkered flag at the top of the circle next to the start/finish line
            canvas.append("rect")
                .attr("x", width / 2 )
                .attr("y", (height/2 -radius)-40)
                .attr("width", 40)
                .attr("height", 25)
                .style("fill", "url(#checkered)")


            
            // draw drivers in the circle of doom depending on their lap distance in relation to the track length
            let data = this.props.drivers;
            let trackLength = this.props.trackLength;

            const driverCircleOfDoom = canvas.append("g")
                .attr("cx", width /2)
                .attr("cy", height/ 2)
                .selectAll(".circle")
                .data(data)
                .enter()
                .append("g")
                .attr("transform", (d) => {
                    let angle = (d.lapDistance! / trackLength) * 2 * Math.PI - Math.PI / 2;
                    let x = Math.cos(angle) * radius + width / 2;
                    let y = Math.sin(angle) * radius + height / 2;
                    return `translate(${x}, ${y})`;
                })
            
            driverCircleOfDoom
                .append("circle")
                .attr("r", 15)
                .style("fill", (d:SessionDriver) => this.colors[d.team!])

            driverCircleOfDoom
                .append("text")
                .text((d: SessionDriver) => d.position!)
                .attr("dx", (d: SessionDriver) => -7)
                .attr("dy", (d: SessionDriver) => 5)
                .style("stroke", "white")
                .style("fill", "white")
                .style("font-weight", "bold")
                .style("stroke-width", "1px")
            return;
        }

        
        const trackSize = trackSizeDict[this.props.trackId];

        if (widthNumber > heightNumber * trackSize.aspectRatio) {
            widthNumber = heightNumber * trackSize.aspectRatio;
        }

        const track = canvas.append("g")
            .append("image")
            .attr("xlink:href", this.getImageForTrackId(this.props.trackId))
            .attr("width", widthNumber)
            .attr("x", margin/2)
            .attr("y", margin/2)

            // .attr("preserveAspectRatio", "none")
            // make image grayscale
            .attr("filter", "grayscale(1) brightness(0) invert(1)")

        let calculatedWidth = track.node()?.getBBox().width as number;
        let calculatedHeight = track.node()?.getBBox().height as number;

        //get the aspect ratio of the track image


        console.log("calculatedWidth", calculatedWidth, "calculatedHeight", calculatedHeight);

        let scaleOffsetX: (arg0: number) => number;
        let scaleOffsetZ: (arg0: number) => number;

        const rangeX = [trackSize.xMin, trackSize.xMax];
        const rangeZ = [trackSize.zMin, trackSize.zMax];

        scaleOffsetZ =  (x:number) => d3.scaleLinear().domain(rangeZ).range([0, calculatedHeight as number]).clamp(false)(x)+margin/2;
        scaleOffsetX = (x:number) => d3.scaleLinear().domain(rangeX).range([0, calculatedWidth as number]).clamp(false)(x)+margin/2;
        
        let data = this.props.drivers

        const circleRotation = 0;

        const g = canvas.append("g")
            .selectAll(".circle")
            .data(data)
            .enter()
            .append("g")
            
            .attr("transform", (d) => `translate(${scaleOffsetX(d.livePosition?.x!)}, ${scaleOffsetZ(d.livePosition?.z!)}) rotate(${-circleRotation})`)
            .append("g")
            .attr("dx", -5)
            .attr("dy", -5)

        g.append("circle")
            .attr("r", 15)
            .style("fill", (d:SessionDriver) => this.colors[d.team!])

        
        // g.append("text")
        //     .text((d: SessionDriver) => d.name!)
        //     .attr("dx", (d: SessionDriver) => 20)
        //     .style("stroke", "white")
        //     .style("fill", "black")
        //     .style("font-weight", "bold")
        //     .style("stroke-width", "1px")

        g.append("text")
            .text((d: SessionDriver) => d.position!)
            .attr("dx", (d: SessionDriver) => -7)
            .attr("dy", (d: SessionDriver) => 5)
            .style("stroke", "white")
            .style("fill", "white")
            .style("font-weight", "bold")
            .style("stroke-width", "1px")
    }

}

