import { Component } from "react";
import cx from "classnames";
import "./leaderboard.scss";
import { LapTime } from "./LapTimeComponent";

type SectorTimesProp = {
    currentSector1Time: number,
    currentSector2Time: number,
    currentSector3Time: number,
    bestSector1Time: number,
    bestSector2Time: number,
    bestSector3Time: number,
    lastSector1Time: number,
    lastSector2Time: number,
    lastSector3Time: number,
    totalBestSector1Time: number,
    totalBestSector2Time: number,
    totalBestSector3Time: number,
}

export class SectorTime extends Component<SectorTimesProp> {

    
    render() {

        let isNewLap = this.props.lastSector1Time === 0 || (this.props.currentSector2Time > 0 || this.props.currentSector1Time > 15000);
        let sector1Color, sector2Color, sector3Color;
        

        let sector1Time = isNewLap ? this.props.currentSector1Time : this.props.lastSector1Time;
        let sector2Time = isNewLap ? this.props.currentSector2Time : this.props.lastSector2Time;
        let sector3Time = isNewLap ? this.props.currentSector3Time : this.props.lastSector3Time;

        if (isNewLap) {
            sector1Color = this.props.currentSector2Time <= 0 ?  "" : this.props.currentSector1Time < this.props.bestSector1Time ? "green" : "red";
            sector2Color = this.props.currentSector3Time <= 0 ?  "" : this.props.currentSector2Time < this.props.bestSector2Time ? "green" : "red";
            sector3Color = "";
        } else {
            sector1Color = sector1Time <= this.props.bestSector1Time ? ( sector1Time <= this.props.totalBestSector1Time ? "violet" : "green" ) : "red";
            sector2Color = sector2Time <= this.props.bestSector2Time ? ( sector2Time <= this.props.totalBestSector2Time ? "violet" : "green" ) : "red";
            sector3Color = sector3Time <= this.props.bestSector3Time ? ( sector3Time <= this.props.totalBestSector3Time ? "violet" : "green" ) : "red";
        }
        return (                        
        <div className="sectorsContainer">
            <div className="sectorContainer">
                <div className={cx("sectorEllipse", sector1Color)}></div>
                <div className={cx("sectorTime", sector1Color)}><LapTime time={sector1Time} showMinutes={false}/></div>
            </div>
            <div className="sectorContainer">
                <div className={cx("sectorEllipse", sector2Color)}></div>
                <div className={cx("sectorTime", sector2Color)}><LapTime time={sector2Time} showMinutes={false} /></div>
            </div>
            <div className="sectorContainer">
                <div className={cx("sectorEllipse", sector3Color)}></div>
                <div className={cx("sectorTime", sector3Color)}><LapTime time={sector3Time} showMinutes={false}/></div>
            </div>
        </div>);
    }
}