import { AppBar, Box, Button, Container, IconButton, InputBase, Link, Menu, MenuItem, Toolbar, alpha, styled } from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import { useAppSelector } from "../hooks";
import { useDispatch } from "react-redux";
import { logoutUser } from "../features/user/userActions";
import SearchIcon from '@mui/icons-material/Search';
import { push } from "redux-first-history";
import { AccountCircle } from "@mui/icons-material";
import React from "react";
import discordImage from "../images/discord-mark-white.svg";

export default function NavbarComponent(){

    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(logoutUser() as any);
    };

    const pages = [
        // { name: "Home", path: "/" },
        { name: "Live", path: "/live" },
        { name: "FAQ", path: "/faq" },
        // { name: "Sessions", path: "/sessions" },
    ];


    const handleSearch = (event: React.FormEvent<HTMLDivElement>) => {
        event.preventDefault();
        const searchString = event.currentTarget.querySelector("input")?.value;
        dispatch(push("/search/" + searchString) as any);
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const loggedIn = useAppSelector(state => state.loggedIn);
        return  (<AppBar position="static">
            <Container maxWidth={false}>
                <Toolbar disableGutters sx={{display:"flex", justifyContent:"space-between"}}>
                    <Box sx={{ display: {  md: 'flex' } }}>
                    <Button 
                        variant="text" 
                        color="inherit" 
                        component={RouterLink}
                        to="/"
                      startIcon={<img src="/logo.png" alt="logo" style={{width:"40px", height:"40px", marginRight:"10px"}}/>}
                      sx={{marginLeft:"10px"}}
                    >
                      
                      Home
                    </Button>
                        
                        {pages.map(page => {
                            return <Button component={RouterLink} to={page.path} key={page.name} color="inherit">{page.name} </Button>
                        })}

                        
                    
                    </Box>
                    <Box component="form" onSubmit={handleSearch} sx={{flexGrow:1, maxWidth: "500px"}}>
                            <Search sx={{marginLeft:"50px"}}>
                                <SearchIconWrapper>
                                <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                                />
                            </Search>
                        </Box>
                    <Box sx={{display:"flex"}}> 
                    <Button variant="contained" color="secondary">
                        <Link href="/download" color="inherit" sx={{textDecoration:"none"}} target="_blank">Download App</Link>
                    </Button>
                    <Button 
                        variant="text" 
                        color="inherit" 
                        href="https://dsc.gg/pitwallcompanion" 
                        target="_blank"
                      startIcon={<img src={discordImage} alt="discord" style={{width:"20px", height:"20px"}}/>}
                      sx={{marginLeft:"10px"}}
                    >
                      Discord
                    </Button>
                    { loggedIn.userInfo && 
                    <Box>
                      <IconButton
                        onClick={handleMenu}
                      >
                        <AccountCircle/>
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem>
                          <Button color="inherit" component={RouterLink} to={"/user/"+loggedIn.userInfo.name} onClick={handleClose}>My Profile</Button>
                        </MenuItem>
                        <MenuItem>
                          <Button color="inherit" component={RouterLink} to="/profile" onClick={handleClose}>My Account</Button>
                        </MenuItem>
                        <MenuItem>
                          <Button color="error" onClick={() => {handleLogout();handleClose()}}>Logout</Button>
                        </MenuItem>
                      </Menu>
                    </Box> }
                    { !loggedIn.userInfo && <Box>
                        <Button color="inherit" component={RouterLink} to="/login" sx={{margin:"0px 10px"}}>Login</Button>
                        <Button color="inherit" component={RouterLink} to="/signup" sx={{margin:"0px 10px"}}>Sign Up</Button>
                    </Box>}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>)
    }

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        minWidth: "200px",
        [theme.breakpoints.up('sm')]: {
          width: 'auto',
        },
      }));
      
      const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }));
      
      const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'inherit',
        width: '100%',
        '& .MuiInputBase-input': {
          padding: theme.spacing(1, 1, 1, 0),
          // vertical padding + font size from searchIcon
          paddingLeft: `calc(1em + ${theme.spacing(4)})`,
          transition: theme.transitions.create('width'),
          [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
              width: '20ch',
            },
          },
        },
      }));

