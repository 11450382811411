import { UserClient, LoginRequest, RefreshRequest, SignUpForm, InfoRequest } from "../ApiClient";

class AuthService{

    private userClient: UserClient;
    private accountClient: UserClient;

    private token: string | undefined;

    public  user: any | undefined;

    private _isLoggedIn: boolean = false;

    constructor(){
        this.userClient = new UserClient();
        this.accountClient = new UserClient();
        // load token from local storage
    }

    async loginUser(email: string, password: string): Promise<boolean>{
        let loginRequest = new LoginRequest();
        loginRequest.email = email;
        loginRequest.password = password;
        return this.accountClient.login(true, true, loginRequest).then((response) => {
            if(response !== undefined){
                // let token;
                // this.handleSuccessfulLogin(response);
                return true;
            }
            return false;
        });
    }



    async registerUser(email: string, password: string, username: string=""): Promise<boolean>{
        if(username !== ""){
            // do other stuff
            var signUpForm = new SignUpForm();
            signUpForm.email = email;
            signUpForm.password = password;
            signUpForm.name = username;
            var userClient = new UserClient();
            return userClient.signUp(signUpForm).then((response) => {
                if(response !== undefined){
                    return true;
                }
                return false;
            });
        } else{
            let registerRequest = new SignUpForm();
            registerRequest.email = email;
            registerRequest.password = password;
            return this.accountClient.signUp(registerRequest).then((response) => {
                if(response !== undefined){
                    return true;
                }
                return false;
            });
        }
    }

    async refreshToken(){
        let refreshRequest = new RefreshRequest();
        refreshRequest.refreshToken = this.token;
        return this.accountClient.refresh(refreshRequest).then((response) => {
            if(response !== undefined){
                return true;
            }
            return false;
        });
    }


    public get isLoggedIn(){
        return this._isLoggedIn;
    }

    public getCurrentUser(){
        return this.user;
    }

    public logout(){
        this.userClient.logout();
    }

    public getAuthorizationHeader(){
        return "Bearer " + this.token;
    }

    public deleteAccount(): Promise<any>{
        return this.userClient.delete();
    
    }

    public changePassword(newPassword: string, oldPassword: string): Promise<any>{
        let infoRequest = new InfoRequest();
        infoRequest.newPassword = newPassword;
        infoRequest.oldPassword = oldPassword;
        return this.accountClient.updateInfo(infoRequest);
    }
}



const authService = new AuthService();
export default authService;