import { UserClient } from "../ApiClient";


class UserService {
  
    private userClient = new UserClient();

    getUserInfo() {
        return this.userClient.getUserInfo();
    }
}
const userService = new UserService();
export default userService;