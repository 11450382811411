import { Container, Box, Typography } from "@mui/material";


export default function ConfirmEmailComponent() {

    return (
        <Container>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "60vh", flexDirection: "column" }}>
                <Typography variant="h1">Confirm Email</Typography>
                <Typography variant="body1">Your registration is nearly finished, we have sent you an email with a confirmation link. Please click on the link to confirm your email address.</Typography>
            </Box>
        </Container>
    )
}