import { configureStore } from '@reduxjs/toolkit'
import loggedInReducer, { userInfoMiddleware } from './features/user/userSclice'
import { combineReducers } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';



const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ 
  history: createBrowserHistory(),
  //other options if needed 
});

const createRootReducer = () => combineReducers({
  router: routerReducer,
  loggedIn: loggedInReducer,
});




const store = configureStore({
  reducer: createRootReducer(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(userInfoMiddleware).concat(routerMiddleware)
})


export default store;
export const history = createReduxHistory(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch