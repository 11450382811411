import { Component } from "react"
import { LapTime } from "./LapTimeComponent"
import cx from "classnames";

export type LapTimesInfoProps = {
    times: {
        bestLapTime: number,
        lastLapTime: number,
    }
    hasBestLap?: boolean,
}

export class LapTimeInfoComponent extends Component<LapTimesInfoProps> {


    render() {

        return (
            <div className="lapTimesContainer">
                <div className={cx("lapTimeContainer")}><div>Last </div><LapTime time={this.props.times.lastLapTime}></LapTime></div>
                <div className={cx("lapTimeContainer", {"best": this.props.hasBestLap})}><div>Best </div><LapTime time={this.props.times.bestLapTime}></LapTime></div>
            </div>
        )
    }
}