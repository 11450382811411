import { Component } from "react";
// import * as d3 from "d3";


interface TelemetryEntry {
    throttle: number;
    brake: number;
    steering: number;
    speed: number;
    rpm: number;
    gear: number;
    lapDistance: number;
}

interface TelemetryState {
    selectedDriver: number;
    selectedLap: number;
}

export class TelemetryComponent extends Component<{}, TelemetryState>{



    ref: SVGElement | null = null;
    containerRef: Element | null = null;
    chart: any;

    constructor(props: {}) {
        super(props);
        this.state = {
            selectedDriver: 0,
            selectedLap: 1
        }
    }

    componentDidMount() {

        fetch("/api/telemetry/10992368972504023293/1/1")
            .then(res => res.json())
            .then(data => {
                // @ts-ignore
                // this.buildThrottleBrakeChart(data);
                this.buildZoomChart(data);
            });
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<TelemetryState>, snapshot?: any): void {
        fetch(`/api/telemetry/15476142660741812779/${this.state.selectedDriver}/${this.state.selectedLap}`)
            .then(res => res.json())
            .then(data => {
                this.buildZoomChart(data);
            });
    }


    private buildZoomChart(chartData: TelemetryEntry[]) {
        // const dataTypes = ["throttle", "brake"];
        // let width = 1600;
        // let height = 400;

        // const color = d3.scaleOrdinal()
        //     .range(['#4daf4a', '#e41a1c', '#377eb8', '#984ea3', '#ff7f00', '#ffff33', '#a65628', '#f781bf', '#999999'])
        // d3.select(this.containerRef).selectAll("svg").remove();
        // var svg = d3.select(this.containerRef).append("svg").attr("viewBox", [0, 0, width, height]);

        // // @ts-ignore
        // const data = chartData.map(d => { return dataTypes.map(dt => { return { type: dt, value: d[dt], time: d.lapDistance } }).flat() }).flat();

        // const sumstat = d3.group(data, d => d.type);
        // console.log(sumstat);

        // const view = svg.append("rect")
        //     .attr("class", "view")
        //     .attr("x", 0.5)
        //     .attr("y", 0.5)
        //     .attr("width", width - 1)
        //     .attr("height", height - 1);

        // var inputX = d3.scaleLinear()
        //     // @ts-ignore
        //     .domain(d3.extent(data, function (d) { return d.time; }) as number[])
        //     .range([-1, width + 1]);
        // // Add Y axis
        // var inputY = d3.scaleLinear()
        //     .domain([0, 1 as number])
        //     .range([height - 20, 20]);

        // const xAxis = d3.axisBottom(inputX)
        //     .ticks(((width + 2) / (height + 2)) * 5)
        //     .tickSize(height)
        //     .tickPadding(8 - height)

        // const yAxis = d3.axisRight(inputY)
        //     .ticks(10)
        //     .tickSize(8)
        //     .tickPadding(8)

        // const gX = svg.append("g")
        //     .call(xAxis);

        // const gY = svg.append("g")
        //     .call(yAxis);

        // const lines = svg.append("g")

        // lines.append("g")
        // .selectAll(".lines")
        // .append("g")
        //     .data(sumstat)
        //     .enter()
        //     .append("path")
        //     .attr("class", "lines")
        //     .attr("d", function (d) {
        //       return d3.line()
        //         // @ts-ignore
        //         .x(d => inputX(d.time))
        //         // @ts-ignore
        //         .y(d => inputY(d.value))
        //         // @ts-ignore
        //         (d[1])
        //     })
        //     .attr("fill", "none")
        //     .attr("stroke-width", 1)
        //     // @ts-ignore
        //     .attr("stroke", d => color(d[0]))

        // // lines.append("path")
        // //     .datum(data)
        // //     .attr("class", "line")
        // //     .attr("fill", "none")
        // //     .attr("stroke-width", 2)
        // //     .attr("stroke", "steelblue")
        // //     .attr("d", function (d) {
        // //         return d3.line()
        // //             // @ts-ignore
        // //             .x(d => inputX(d.time))
        // //             // @ts-ignore
        // //             .y(d => inputY(d.value))
        // //             // @ts-ignore
        // //             (d)
        // //     })

        // const zoomed = ({ transform }: any) => {
        //     // view.attr("transform", transform);
        //     gX.call(xAxis.scale(transform.rescaleX(inputX)));
        //     // gY.call(yAxis.scale(transform.rescaleY(inputY)));

        //     var newX = transform.rescaleX(inputX);
        //     // var newY = transform.rescaleY(inputY);
        //     lines.selectAll(".lines").attr("d", function (d) {
        //         return d3.line()
        //             // @ts-ignore
        //             .x(d => newX(d.time))
        //             // @ts-ignore
        //             .y(d => inputY(d.value))
        //             // @ts-ignore
        //             (d[1])
        //     })

        //     // lines.select(".line").attr("d", function (d) {
        //     //     return d3.line()
        //     //         // @ts-ignore
        //     //         .x(d => newX(d.time))
        //     //         // @ts-ignore
        //     //         .y(d => inputY(d.value))
        //     //         // @ts-ignore
        //     //         (d)
        //     // })
        // }

        // const filter = (event: any) => {
        //     event.preventDefault();
        //     return (!event.ctrlKey || event.type === 'wheel') && !event.button;
        // }

        // const zoom = d3.zoom()
        //     .scaleExtent([1, 400])
        //     .translateExtent([[0, 0], [width + 90, height + 100]])
        //     .filter(filter)
        //     .on("zoom", zoomed);

        // const reset = () => {
        //     // @ts-ignore
        //     svg.transition().duration(750).call(zoom.transform, d3.zoomIdentity);
        // }

        // // @ts-ignore
        // svg.call(zoom);
    }

    private buildThrottleBrakeChart(chartData: TelemetryEntry[]) {
        // const dataTypes = ["throttle", "brake"];



        // // @ts-ignore
        // // const data = this.state.driverTelemetries[this.state.selectedDriver].lapTelemetries[this.state.selectedLap].lapTelemetry.map(d => { return dataTypes.map(dt => {return {type: dt, value: d[dt], time:d.timestamp}}).flat()} ).flat();
        // const data = chartData.map(d => { return dataTypes.map(dt => { return { type: dt, value: d[dt], time: d.lapDistance } }).flat() }).flat();
        // // console.log(this.state.driverTelemetries[0].lapTelemetries[0]);
        // //const maxValue = 1;
        // //const minValue = 0;

        // var margin = { top: 10, right: 30, bottom: 30, left: 60 };
        // const width = 1600 - margin.left - margin.right;
        // const height = 400 - margin.top - margin.bottom;

        // d3.select(this.ref).selectAll("*").remove();

        // const throttleGraph = d3.select(this.ref)
        //     //   .append("svg")
        //     //   .attr("width", width + margin.left + margin.right)
        //     //   .attr("height", height + margin.top + margin.bottom)
        //     // .on("pointerenter pointermove", pointermoved)
        //     // .on("pointerleave", pointerleft)
        //     // .on("touchstart", event => event.preventDefault())
        //     .append("g")
        //     .attr("transform",
        //         "translate(" + margin.left + "," + margin.top + ")");

        // // @ts-ignore
        // const sumstat = d3.group(data, d => d.type);

        // const color = d3.scaleOrdinal()
        //     .range(['#4daf4a', '#e41a1c', '#377eb8', '#984ea3', '#ff7f00', '#ffff33', '#a65628', '#f781bf', '#999999'])


        // var inputX = d3.scaleLinear()
        //     // @ts-ignore
        //     .domain(d3.extent(data, function (d) { return d.time; }) as number[])
        //     .range([0, width]);

        // const gX = throttleGraph.append("g")
        //     .attr("transform", "translate(0," + height + ")")
        //     .call(d3.axisBottom(inputX));

        // // Add Y axis
        // var inputY = d3.scaleLinear()
        //     .domain([0, 1 as number])
        //     .range([height, 0]);

        // const gY = throttleGraph.append("g")
        //     .call(d3.axisLeft(inputY));

        // throttleGraph.append("g")
        //     .selectAll(".line")
        //     .append("g")
        //     // .attr("class", "line")
        //     .data(sumstat)
        //     .enter()
        //     .append("path")
        //     .attr("d", function (d) {
        //         return d3.line()
        //             // @ts-ignore
        //             .x(d => inputX(d.time))
        //             // @ts-ignore
        //             .y(d => inputY(d.value))
        //             // @ts-ignore
        //             (d[1])
        //     })
        //     .attr("fill", "none")
        //     .attr("stroke-width", 2)
        //     // @ts-ignore
        //     .attr("stroke", d => color(d[0]))

    }

    private rebuildChart(){
        fetch(`/api/telemetry/15476142660741812779/${this.state.selectedDriver}/${this.state.selectedLap}`)
            .then(response => response.json())
            .then(data => {
                this.buildZoomChart(data);
            });
    }

    changeDriver = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({selectedDriver: parseInt(event.target.value)});
        // this.rebuildChart();
      }
    
      changeLap = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({selectedLap: parseInt(event.target.value)});
        // this.rebuildChart();
      }

    render() {

        return (<div ref={(ref) => this.containerRef = ref}>
                  <select onChange={this.changeDriver} value={this.state.selectedDriver}>
                    {[...Array(20).keys()].map((d,idx) => <option key={d} value={idx}>{d}</option>)}
                </select>
                <select onChange={this.changeLap} value={this.state.selectedLap}>
                {[...Array(6).keys()].map((d,idx) => <option key={d} value={idx}>{d}</option>)}
                </select>
        </div>)
    }

}