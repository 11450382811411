import React from "react";



export class DriverNameComponent extends React.Component<{ driverName: string, carNumber: number, teamId: number }> {


    driverMap = {
        "29": {
            default:"Litzuck",
            "0": "F1Racer",
            "6": "Litzuck",
        },
        "19": {
            "6": "Rakun",
            "7": "Fuenni"
        },
        "69": {
            "1": "i_kill_4you",
            "7": "Kampfoger420"
        }, 
        "12": {
            "3": "Leptys",
            "5": "Jooghurt"
        },
        "66": "Marcnesium",
        "64": "gruhnd",
        "96": {
            "3": "Jan Fehler",
            "4": "Leniovias"
        },
        "15": "Barazzo",
        "5": "MiziMaxi",
        "98": "IluPlays",
        "27": "yunico",


    }

    teamMap = {
        "0": "Mercedes",
        "1": "Ferrari",
        "2": "Red Bull",
        "3": "Williams",
        "4": "Aston Martin",
        "5": "Alpine",
        "6": "Alpha Tauri",
        "7": "Haas",
        "8": "McLaren",
        "9": "Alfa Romeo",
    }

    teamIdMap = ["Mercedes", "Ferrari", "Red Bull", "Williams", "Aston Martin", "Alpine", "Alpha Tauri", "Haas", "McLaren", "Alfa Romeo"]

    render() {

        // return <div className="driverName">{this.getDriverByNumberAndTeamId(this.props.carNumber, this.props.teamId)}</div>;
        return <div className="driverName">{this.props.driverName + "#" + this.props.carNumber}</div>;
    }


    getDriverByNumberAndTeamId(carNumber: number, teamId: number) {
        // @ts-ignore
        let a = this.driverMap[carNumber.toString()];
        if (typeof a === "string") {
            return a;
        } else if (typeof a === "object") {
            return a[teamId.toString()] ? a[teamId.toString()] : (`${this.teamIdMap[teamId]}#${carNumber}`);
        }
        return `${this.teamIdMap[teamId]}#${carNumber}`;

    }

}