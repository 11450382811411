import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import store, {history} from './store';
// import { Router } from 'react-router-dom';
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { loadUserDataFromLocalStorage } from './features/user/userActions';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

store.dispatch(loadUserDataFromLocalStorage() as any);
// const history = syncHistoryWithStore(browserHistory, store);
root.render(
  <React.StrictMode>
    
    <Provider store={store}>
      <Router history={history}>
    {/* <BrowserRouter> */}
      <App />
    {/* </BrowserRouter> */}
    </Router>
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
