import { SessionDriver } from "../../ApiClient";
import DriverChartComponent from "./DriverChartComponent";


export default function LapTimesChartComponent({ driverData }: {driverData:SessionDriver[]}) {

    driverData = driverData.sort((x, y) => x.position! - y.position!);

    let lapTimesData = driverData.map(d => {
        return {
            name: d.name!,
            team: d.team!,
            position: d.position!,
            values: d.lapTimes!.map(l => l.lapTime!/1000).filter(l => l > 0)
        }
    });

    return (
        <DriverChartComponent driverData={lapTimesData} xLabel="Lap" yLabel="Lap Times (s)"/>
    )
}