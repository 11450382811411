import React, { Component } from "react";


type TimeProps = {
    time:number
    lapNumber:number
    leaderTime:number
    leaderLap:number
}


export class DiffTime extends Component<TimeProps> {

    formatTime(time: number, showMinutes: boolean = false) {
        // console.log(time);
        if (time === null || time === undefined || isNaN(time) || time > 3600000 || time <= 0) {
            if(showMinutes){
                return "--:--.---";
            }
            return "--.---";
        }
        const neg = time < 0;
        time = Math.abs(time);
        const minutes = Math.floor(time / 60000);
        const seconds = Math.floor((time - minutes * 60000) / 1000);
        const milliseconds = Math.floor(time % 1000);
        showMinutes = showMinutes || minutes > 0;
        if(!showMinutes){
            return (neg ? '-' :'') + (seconds < 10 ? '0' : '') + seconds + '.' + (milliseconds < 10 ? '00' : milliseconds < 100 ? '0' : '') + milliseconds;
        }
        return (neg ? '-' :'') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds + '.' + (milliseconds < 10 ? '00' : milliseconds < 100 ? '0' : '') + milliseconds;
    }

    render(){
        if (this.props.lapNumber === this.props.leaderLap) {
        return (<div>+{this.formatTime(this.props.time -this.props.leaderTime)}</div>);
        } else {
            return (<div> +{ this.props.leaderLap - this.props.lapNumber} Lap</div>);
        }
    }
}