import { Container, Typography, Button, Table, TableContainer, Paper, TableBody, TableRow, TableCell, TableHead } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import { UserClient } from "../ApiClient";
import { Link } from "react-router-dom";

type SearchState = {
    searchResults: string[],
}

type SearchProps = {
    searchString: string
}

export function SearchComponent() {
    const { searchString } = useParams();


    return (
        <InnerSearchComponent searchString={searchString!} />
    );
}

class InnerSearchComponent extends React.Component<SearchProps, SearchState> {
    constructor(props: SearchProps) {
        super(props);
        this.state = {
            searchResults: []
        };
    }

    componentDidMount() {
        document.title = "Pitwall Companion - Search";

        this.search();
    }

    componentDidUpdate(prevProps: Readonly<SearchProps>, prevState: Readonly<SearchState>, snapshot?: any): void {
        if (prevProps.searchString !== this.props.searchString)
            this.search();
    }

    search = () => {
        const client = new UserClient();
        client.searchUser(this.props.searchString).then(results => {
            this.setState({ searchResults: results });
        });
    }

    render() {
        return (
            <Container>
                <Typography variant="h3">Search</Typography>
                <SearchResults searchResults={this.state.searchResults} />
            </Container>
        );
    }
}

function SearchResults(props: { searchResults: string[] }) {
    return (
        <div>
            <div>
                <Typography variant="h4">Results</Typography>
            </div>
            
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography>Name</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>Profile</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>Live Session</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                    {props.searchResults.map(result => {
                        return (
                            <TableRow key={result}>
                                <TableCell>
                                    <Typography>{result}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Button component={Link} to={"/user/" + result} variant="contained">View Profile</Button>
                                </TableCell>
                                <TableCell>
                                    <Button component={Link} to={"/user/" + result+"/live" } variant="contained">View Live Session</Button>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                    {props.searchResults.length === 0 && <TableRow>
                        <TableCell colSpan={3}>
                            <Typography>No results found</Typography>
                        </TableCell>
                    </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}