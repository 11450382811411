import React from "react";

import "./team.scss";

export class TeamComponent extends React.Component<any, any> {



    render() {

        switch (this.props.teamId) {
            case 0:
                return (<div className="team team-mercedes"></div>);
            case 1:
                return (<div className="team team-ferrari"></div>);
            case 2:
                return (<div className="team team-redbull"></div>);
            case 3:
                return (<div className="team team-williams"></div>);
            case 4:
                return (<div className="team team-aston-martin"></div>);
            case 5:
                return (<div className="team team-alpine"></div>);
            case 6:
                return (<div className="team team-alpha"></div>);
            case 7:
                return (<div className="team team-haas"></div>);
            case 8:
                return (<div className="team team-mclaren"></div>);
            case 9:
                return (<div className="team team-sauber"></div>);

            default:
                return (<div className="team">{this.props.teamId}</div>);
        }

    }
}