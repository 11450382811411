import { ResultStatus, SessionType } from "../ApiClient";

export function getSessionNameFromType(type: number) {
    switch(type) {
        case SessionType.P1:
        case SessionType.P2:
        case SessionType.P3:
        case SessionType.Q1:
        case SessionType.Q2:
        case SessionType.Q3:
            return SessionType[type];

        case SessionType.Race:
        case SessionType.Race2:
            return "Race";

        case SessionType.ShortPractice:
            return "Short Practice";
        case SessionType.ShortQualifying:
            return "Short Qualifying";
        case SessionType.OneShotQualifying:
            return "One Shot Qualifying";
        case SessionType.TimeTrial:
            return "Time Trial";

        default:
            return "Unknown";
    }
}


export function getResultStatusString(status: ResultStatus) {
    switch(status) {
        case ResultStatus.Active:
            return "Active";
        case ResultStatus.Retired:
            return "Retired";
        case ResultStatus.Disqualified:
            return "DSQ";
        case ResultStatus.DidNotFinish:
            return "DNF";
        case ResultStatus.Finished:
        case ResultStatus.Inactive:
        case ResultStatus.Invalid:
        case ResultStatus.NotClassified:
            return ResultStatus[status];

        default:
            return "Unknown";
    }
}

const formatter = new Intl.DateTimeFormat(navigator.language, { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false, weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'});

export function formatDateTime(time: number) {
    const date = new Date(time);    
    return formatter.format(date);
}
