import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";



export default function FooterComponent() {
  return (
    <footer>
        <Box sx={{ width: "100vw", backgroundColor: "black", color: "white", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "1rem"}}
        >
            <Link to="/privacy" style={{color:"white", textDecoration:"none"}}>Privacy Policy</Link>
            <Link to="/impressum" style={{color:"white", textDecoration:"none"}}>Impressum</Link>
            {/* <p>© 2024 - All rights reserved</p> */}
            <Typography variant="caption">Track images are copyright of F1Laps. <br/>
            Assets of Formula 1 and Codemasters are property of their respective owners </Typography>
        </Box>
    </footer>
  );
}