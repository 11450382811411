import { useParams } from "react-router-dom";
import { Session, SessionsClient, UserClient, PublicUserInfo } from "../ApiClient";
import { Component } from "react";
import { SessionRow } from "./SessionsComponent";
import { Box, Button, CircularProgress, Container, List, Pagination, Paper, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";


export function ProfileComponent() {


    const { userId } = useParams();

    return (
        <InnerProfileComponent userId={userId!} />
    );

}

interface ProfileState {
    user: PublicUserInfo | undefined,
    sessions: Session[] | undefined,
    page: number,
    totalPages: number
}

interface ProfileProps {
    userId: string
}

class InnerProfileComponent extends Component<ProfileProps, ProfileState>{

    constructor(props: any) {
        super(props);
        this.state = {
            user: undefined,
            sessions: undefined,
            page: 0,
            totalPages: 0
        };
    }

    componentDidMount(): void {
        document.title = "Pitwall Companion - Profile";

        const client = new UserClient();
        const sessionsClient = new SessionsClient();

        client.getUser(this.props.userId).then(user => {
            this.setState({ user: user });
        });

        sessionsClient.getUserSessionsById(this.props.userId, this.state.page).then(result => {
            this.setState({ sessions: result.sessions, totalPages: result.totalPages!});
        });
    }

    handlePageChange(event: any, value: number) {
        this.setState({ page: value-1 });
        const sessionsClient = new SessionsClient();
        sessionsClient.getUserSessionsById(this.props.userId, value-1).then(result => {
            this.setState({ sessions: result.sessions, totalPages: result.totalPages!});
        });
    }

    render() {
        if(this.state.user === undefined || this.state.sessions === undefined){
            return (<Container sx={{display:"flex", alignItems:"center", justifyContent:"center", minHeight:"50vh"}}>
                <CircularProgress/>
            </Container>);
        }

        //get the user name of the current user from store
        
        
        // let isOwner = this.state.user.name === user?.name;
        return (
            <Container>
                <Paper sx={{margin:"10px", padding:"10px"}}>
                <Typography variant="h3">{this.state.user?.name}</Typography>
                <Typography variant="h5">Member since: {this.state.user?.createdAt!.toLocaleDateString()}</Typography>


                <Button variant="contained" color="primary" sx={{margin:"10px"}} component={RouterLink} to={"/user/"+this.state.user?.name+ "/live"}>Watch Live</Button>

                <Typography variant="h4">Sessions:</Typography>
                <List>
                    {this.state.sessions?.map(session => {
                        return <SessionRow session={session} showUser={false} format="compact"/>
                    })}
                </List>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination count={this.state.totalPages+1} color="primary" onChange={this.handlePageChange.bind(this)} size="large"/>
                </Box>
                </Paper>
            </Container>
        );
    }
}