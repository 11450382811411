import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AuthService from '../services/AuthService';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { push } from "redux-first-history";
import { CircularProgress } from '@mui/material';


function SignUp() {

    const [errors, setErrors] = React.useState([] as string[]);
    const [loading, setLoading] = React.useState(false);
    
    const dispatch = useDispatch();
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let errs = [];
        let isValid = true;
        //check if email is valid
        if (!data.get('email')?.toString().match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
            errs.push("Invalid email");
            isValid = false;
        }
        //check if username is valid
        if (!data.get('username')?.toString().match(/^[a-zA-Z0-9_-]{3,}$/)) {
            errs.push("Invalid username, must be at least 3 characters long and contain only letters, numbers, underscores, and hyphens.");
            isValid = false;
        }
        //check if password is valid
        if (!data.get('password')?.toString().match(/.{8,}/) ){
            errs.push("Password must be at least 8 characters long");
            isValid = false;
        }

        if (data.get('password') !== data.get('confirmPassword')) {
            errs.push("Passwords do not match");
            isValid = false;
        }

        if (!isValid) {
            setErrors(errs);
            return;
        }

        setLoading(true);

        AuthService.registerUser(data.get('email') as string, data.get('password') as string, data.get('username') as string).then((response) => {
            setErrors([]);
            //if successful, redirect to login page            
            dispatch(push('/confirmEmail') as any);
            //TODO add render redirect
        }).catch((error) => {
            //for each error, display error message
            // console.log(error);
            setLoading(false);
            setErrors(Object.entries(error.errors).map( (entry, err) => (entry[1] as any)[0]));
        });  
      };


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign Up
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <CustomField
                        label="Email"
                        errorText="Invalid email"
                        regex={/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/}
                        id="email"
                        name="email"
                        required
                        fullWidth
                        autoFocus
                        margin="normal"
                        autoComplete="email"
                    />
                    <CustomField
                        label="Username"
                        errorText="Invalid username"
                        regex={/^[a-zA-Z0-9_-]{3,}$/}
                        id="username"
                        name="username"
                        required
                        fullWidth
                        margin="normal"
                        autoComplete="username" />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        id="confirmPassword"
                        autoComplete='none'
                        />

                    <Box sx={errors.length>0 ? {visibility:"initial"}: {visibility:"hidden"}}>
                        {errors.map((error, index) => {
                            return <Typography color="error" key={index}>{error}</Typography>
                        })}
                    </Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign Up
                    </Button>
                    <Box sx={{ display: loading ? "block" : "none" }}>
                        <CircularProgress />
                    </Box>
                    <Grid container>
                        <Grid item xs>
                            <Link to="/login" variant="body2" color="inherit" component={RouterLink}>
                                Already have an account? Sign In
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

export default SignUp;



interface CustomFieldProps {
    errorText: string;
    regex: any;
    [key: string]: any;
}
class CustomField extends React.Component<CustomFieldProps,any> {
    constructor(props:CustomFieldProps) {
      super(props)
      this.state = { errorText: '', value: props.value }
    }
  
    onChange(event:any) {
      if (event.target.value.match(this.props.regex)) {
        this.setState({ errorText: '' })
      } else {
        this.setState({ errorText: this.props.errorText })
      }
    }
    render() {
        var {regex, errorText, ...props} = this.props;
      return (
        <TextField label={this.props.label}
         helperText={this.state.errorText}
          error={this.state.errorText !== ''}
          onChange={this.onChange.bind(this)}
          value={this.state.value}
            {...props}
            />

      )
    }

}