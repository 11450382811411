import { useMemo } from "react";
import { useChartDimensions } from "./ChartUtils";
import * as d3 from "d3";
import { WeatherForecastSample } from "../../ApiClient";


interface WeatherChartProps {
    weatherData: WeatherForecastSample[];
    xLabel?: string;
    yLabel?: string;
}

const chartSettings = {
    marginLeft: 40,
    marginRight: 40,
    marginTop: 40,
    marginBottom: 80,
}

export default function WeatherChart({ weatherData, xLabel, yLabel }: WeatherChartProps) {


    const [ref,dimensions] = useChartDimensions(chartSettings);


    const xScale = useMemo(() => {
        return d3.scaleLinear()
            .domain([0, weatherData.length-1])
            .range([0, dimensions.boundedWidth!])
    }, [weatherData, dimensions.boundedWidth])

    const tempYScale = useMemo(() => {
        return d3.scaleLinear()
            .domain([d3.min(weatherData, d => Math.min(d.airTemperatureCelsius!,d.trackTemperatureCelsius!))!, d3.max(weatherData, d => Math.max(d.airTemperatureCelsius!,d.trackTemperatureCelsius!))!])
            .range([dimensions.boundedHeight!, 0])
    }, [weatherData, dimensions.boundedHeight]);

    const rainYScale = useMemo(() => {
        return d3.scaleLinear()
            .domain([0, d3.max(weatherData, d => d.rainPercentage!)!])
            .range([dimensions.boundedHeight!, 0])
    }, [weatherData, dimensions.boundedHeight]);


    const airTempLine = useMemo(() => {
        return d3.line<WeatherForecastSample>()
            .x((d, i) => xScale(i))
            .y(d => tempYScale(d.airTemperatureCelsius!))
    }, [xScale, tempYScale]);

    const trackTempLine = useMemo(() => {
        return d3.line<WeatherForecastSample>()
            .x((d, i) => xScale(i))
            .y(d => tempYScale(d.trackTemperatureCelsius!))
    }, [xScale, tempYScale]);

    const rainLine = useMemo(() => {
        return d3.line<WeatherForecastSample>()
            .x((d, i) => xScale(i))
            .y(d => rainYScale(d.rainPercentage!))
    }, [xScale, rainYScale]);

    const  horizontalTicks = useMemo(() => {
        return xScale.ticks(weatherData.length).map(value => ({
            value,
            xOffset: xScale(value)
        }))
    }, [xScale, weatherData.length]);

    const verticalTempTicks = useMemo(() => {
        return tempYScale.ticks(5).map(value => ({
            value,
            yOffset: tempYScale(value)
        }))
    }, [tempYScale]);

    const verticalRainTicks = useMemo(() => {
        return rainYScale.ticks(5).map(value => ({
            value,
            yOffset: rainYScale(value)
        }))
    }, [rainYScale]);

    return (
        <div ref={ref} style={{height: "300px"}}>
            <svg viewBox={`0 0 ${dimensions.width??0} ${dimensions.height??0}`}>
                <rect width={dimensions.width} height={dimensions.height} fill="#000"/>

                <g 
                    transform={`translate(${dimensions.marginLeft}, ${dimensions.marginTop})`}
                >
                    <path d={airTempLine(weatherData)!} fill="none" stroke="lightskyblue" strokeWidth="2"/>

                    <path d={trackTempLine(weatherData)!} fill="none" stroke="green" strokeWidth="2" strokeDasharray="5,5"/>

                    <path d={rainLine(weatherData)!} fill="none" stroke="blue" strokeWidth="2"/>


                    {horizontalTicks.map(({value, xOffset}) => (
                        <g key={value} transform={`translate(${xOffset}, ${dimensions.boundedHeight!})`}>
                            <line y2={10} stroke="#fff"/>
                            <text y={25} dy="1em" style={{fontSize: "12px"}} textAnchor="middle" fill="#fff">{value+1}</text>
                        </g>
                    ))}

                    {verticalTempTicks.map(({value, yOffset}) => (
                        <g key={value} transform={`translate(0, ${yOffset})`}>
                            <line x2={-10} stroke="#fff"/>
                            <text x={-15} dy="0.32em" style={{fontSize: "12px"}} textAnchor="end" fill="#fff">{value}</text>
                        </g>
                    ))}

                    {verticalRainTicks.map(({value, yOffset}) => (
                        <g key={value} transform={`translate(${dimensions.boundedWidth!}, ${yOffset})`}>
                            <line x2={10} stroke="#fff"/>
                            <text x={15} dy="0.32em" style={{fontSize: "12px"}} textAnchor="start" fill="#fff">{value}</text>
                        </g>
                    ))}


                    <path d={`M 0 0 H 0 ${dimensions.boundedWidth}`} stroke="#fff" strokeWidth="2"
                        transform={`translate(0, ${dimensions.boundedHeight})`}
                    />

                    <path d={`M 0 0 V 0 ${dimensions.boundedHeight}`} stroke="#fff" strokeWidth="2"/>

                    <path d={`M 0 0 V 0 ${dimensions.boundedHeight}`} stroke="#fff" strokeWidth="2"
                        transform={`translate(${dimensions.boundedWidth}, 0)`}
                    />

                    <text x={dimensions.boundedWidth!/2} y={dimensions.boundedHeight! + 50} dy="1em" fill="#fff" textAnchor="middle">{"Lap"}</text>

                    <g transform={`translate(0, ${dimensions.boundedHeight!/2}) rotate(-90)`}>
                        <text x={0} y={0} dy="1em" fill="#fff" textAnchor="middle">Temperature (°C)</text>
                    </g>

                    <g transform={`translate(${dimensions.boundedWidth!-20}, ${dimensions.boundedHeight!/2}) rotate(-90)`}>
                        <text x={0} y={0} dy="1em" fill="#fff" textAnchor="middle">Rain Percentage(%)</text>
                    </g>

                    <g transform={`translate(0, ${dimensions.boundedHeight!})`}>
                        <g transform={`translate(0, 50)`}>
                            <rect x={0} y={0} width={20} height={20} fill="lightskyblue"/>
                            <text x={25} y={15} fill="#fff">Air Temp</text>
                        </g>
                        <g transform={`translate(100, 50)`}>
                            <rect x={0} y={0} width={20} height={20} fill="green"/>
                            <text x={25} y={15} fill="#fff">Track Temp</text>
                        </g>
                        <g transform={`translate(220, 50)`}>
                            <rect x={0} y={0} width={20} height={20} fill="blue"/>
                            <text x={25} y={15} fill="#fff">Rain</text>
                        </g>
                    </g>

                </g>
            </svg>
        </div>
    )
}