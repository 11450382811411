import React, { Component } from "react";
import cx from "classnames";

type TimeProps = {
    time:number,
    showMinutes?:boolean,
    isInvalid?:boolean
    showMilliseconds?:boolean
}


export class LapTime extends Component<TimeProps> {

    formatTime(time: number, showMinutes: boolean = false, showMilliseconds: boolean = true) {
        if (time === null || time === undefined || isNaN(time) || time > 3600000 || time <= 0) {
            if(showMinutes){
                return "--:--.---";
            }
            return "--.---";
        }
        const neg = time < 0;
        time = Math.abs(time);
        const minutes = Math.floor(time / 60000);
        const seconds = Math.floor((time - minutes * 60000) / 1000);
        const milliseconds = Math.floor(time % 1000);

        let formattedTime = '';
        formattedTime += (neg ? '-' :'')
        if(showMinutes){
            formattedTime += (minutes < 10 ? '0' : '') + minutes + ':';
        }
        formattedTime += (seconds < 10 ? '0' : '') + seconds;
        if(showMilliseconds){
            formattedTime += '.' + (milliseconds < 10 ? '00' : milliseconds < 100 ? '0' : '') + milliseconds;
        }
        return formattedTime;
    }

    render(){
        const {showMinutes = true, showMilliseconds= true} = this.props
        return (<div style={{display:"inline-block"}} className={cx({"invalidLap": this.props.isInvalid})}>{this.formatTime(this.props.time, showMinutes, showMilliseconds)}</div>);
    }
}