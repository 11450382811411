import { useMemo } from "react";
import { SessionDriver } from "../../ApiClient";
import DriverChartComponent from "./DriverChartComponent";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";

interface GapTimeChartProps {
    driverData: SessionDriver[];
}

export default function GapTimeChartComponent({ driverData }: GapTimeChartProps) {

    // draw a graph of the lap times of all drivers over the laps driven

    const [refDriver, setRefDriver] = React.useState(0);
    const sortedDrivers = driverData.sort((x, y) => x.position! - y.position!);

    const data = sortedDrivers.map(d => d.lapTimes!.map(l => l.lapTime!).filter(l => l > 0));

    //calculate the cumulative time for each driver
    const cumulativeTime = data.map(driver => {
      let time = 0;
      return driver.map(lapTime => {
        time += lapTime;
        return time;
      });
    });

    // substract the smallest time from each lap time to get the time difference to the leader

    const leaderTimes = useMemo(() => cumulativeTime[refDriver], [refDriver,cumulativeTime]);

    const timeDiff = cumulativeTime.map(driver => driver.map((lapTime, i) => (lapTime - leaderTimes[i])/1000));

    const plotData = sortedDrivers.map((driver, i) => {
        return {
            name: driver.name!,
            team: driver.team!,
            position: driver.position!,
            values: timeDiff[i]
        }
    });

    const handleChange = (event: SelectChangeEvent<{ value: unknown }>) => {
        setRefDriver(Number(event.target.value));
    };
    

    return (
        <div>
            <DriverChartComponent driverData={plotData} xLabel="Lap" yLabel="Gap to Reference (s)">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} color="secondary">
                <InputLabel id="reference-driver-label">Reference Driver</InputLabel>
            <Select
                labelId="reference-driver-label"
                label="Reference Driver"
                onChange={handleChange}
                defaultValue={0 as any}
            >
                {sortedDrivers.map((driver,i) => <MenuItem value={i}>{driver.position}-{driver.name}</MenuItem>)}
            </Select>
            </FormControl>
            </DriverChartComponent>
            
        </div>
    );

}