import React, { useEffect } from 'react';
import './App.css';
import { Location, Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import { TelemetryComponent } from './components/TelemetryComponent';
import { SessionsComponent } from './pages/SessionsComponent';
import { SessionComponent } from './pages/SessionComponent';
import { LiveSessionComponent } from './pages/LiveSessionComponent';
import { HomeComponent } from './pages/HomeComponent';
import NavbarComponent from './shared/NavbarComponent';
import Login from './pages/LoginComponent';
import { Box, Container, CssBaseline, ThemeProvider, Typography, createTheme } from '@mui/material';
import SignUp from './pages/SignUpComponent';
import UserAccount from './pages/AccountComponent';
import { useSelector } from 'react-redux';
import { LiveSessionsComponent } from './pages/LiveSessionsComponent';
import { RootState } from './store';
import { CookieModal } from '@schlomoh/react-cookieconsent';
import AnalyticsService from './services/AnalyticsService';
import FooterComponent from './shared/FooterComponent';
import ImpressumComponent from './pages/ImpressumComponent';
import PrivacyComponent from './pages/PrivacyComponent';
import Cookies from 'js-cookie';
import { LiveUserSessionComponent } from './pages/LiveUserSessionComponent';
import { SearchComponent } from './pages/SearchComponent';
import { ProfileComponent } from './pages/ProfileComponent';
import FAQComponent from './pages/FAQComponent';
import ConfirmEmailComponent from './pages/ConfirmEmail';
import ResetPasswordComponent from './pages/ResetPassword';
import ConfirmEmailSuccess from './pages/ConfirmEmailSuccess';
import ResetPasswordConfirmComponent from './pages/ResetPasswordConfirm';
import ResendConfirmationComponent from './pages/ResendConfirmation';

interface ICookieObject {
  [key:string] : boolean;
}

function PrivateRoute({ children, ...rest }: any) {
  const isAuthenticated = useSelector((state: RootState) => state.loggedIn.userInfo !== null || state.loggedIn.success);
  if (isAuthenticated) {
    return children;
  }
  return <Navigate to="/login" />;
}


function App() {

  // useDispatch()(loadUserDataFromLocalStorage() as any);

  const location = useLocation();
  useEffect(() => {
    AnalyticsService.logPageView(location.pathname);
  }, [location]);
  //setup material ui theme
  const theme = createTheme({
    palette: {
      // primary: {
      //   main: "#282c34",
      //   light: "#555555", // Light variant of the primary color
      //   dark: "#000000", // Dark variant of the primary color
      //   contrastText: "#ffffff", // Text color for use on primary color
      // },
      // secondary: {
      //   main: "#f44336", // Main secondary color
      //   light: "#e57373", // Light variant of the secondary color
      //   dark: "#d32f2f", // Dark variant of the secondary color
      //   contrastText: "#ffffff", // Text color for use on secondary color
      // },
          primary: {
            main: '#282c34',
            light: "#555555",
            dark: "#000000",
            contrastText: '#ffffff'
          },
          secondary: {
            main: '#e53935',
            contrastText: '#ffffff'
          },
      mode: "dark"
    }

  })

  var consentCookie = Cookies.get("selection")
  if(consentCookie){
    var consent = JSON.parse(consentCookie);
    if(consent.Analytics){
      AnalyticsService.startAnalytics();
    }
  }

  return (
    <div className="App">
      <CookieModal 
      enableManagement
      managementButtonText='Manage cookie preferences'
      cookieCategories={['Analytics']}
      onAccept={onAccept}
      onDecline={onDecline}
      infoContent={
        <div style={{color:"black"}}>
          <h2>We use cookies</h2>
          <p>
            We use cookies to improve your experience on our website. By clicking "Accept" you consent to the use of cookies.</p>
        </div>
      }
      
      /> 
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NavbarComponent></NavbarComponent>
        <Routes>
          <Route path="/" element={<HomeComponent />} />
          {/* <Route path="/live" element={<LiveSessionComponent />} /> */}
          <Route path="/live" element={<LiveSessionsComponent />} />
          <Route path="/live/:session" element={withRouter(LiveSessionComponent)({})} />
          <Route path="/liveUser/:user" element={withRouter(LiveUserSessionComponent)({})} />
          <Route path="/telemetry2" element={<TelemetryComponent />} />
          <Route path="/sessions" element={<SessionsComponent/>}/>
          <Route path="/sessions/:session" element={
            withRouter(SessionComponent)({})}/>
          <Route path="/login" element={<Login/>} />
          <Route path="/signup" element={<SignUp/>} />
          <Route path="/profile" element={<PrivateRoute>
            <UserAccount/>
          </PrivateRoute>}/>
          <Route path="/impressum" element={<ImpressumComponent/>} />
          <Route path="/privacy" element={<PrivacyComponent/>} />
          <Route path="/search/:searchString" element={<SearchComponent/>} />
          <Route path="/user/:userId" element={<ProfileComponent/>} />
          <Route path="/user/:userId/live" element={withRouter(LiveUserSessionComponent)({})} />
          <Route path="/user/:userId/sessions/:session" element={withRouter(SessionComponent)({})} />
          <Route path="/faq" element={<FAQComponent/>} />
          <Route path="/confirmEmail" element={<ConfirmEmailComponent/>} />
          <Route path="/confirmEmailSuccess" element={<ConfirmEmailSuccess/>} />
          <Route path="/resetPassword" element={<ResetPasswordComponent/>} />
          <Route path="/resetPasswordConfirm" element={<ResetPasswordConfirmComponent/>} />
          <Route path="/resendConfirmation" element={<ResendConfirmationComponent/>} />
          <Route path="*" element={<NotFound/>} />
        </Routes>
        <FooterComponent></FooterComponent>
      </ThemeProvider>
    </div>
  );
}

const onAccept = (cookies: ICookieObject| undefined) => {
  if(!cookies) return;
  if (cookies.Analytics) {
    AnalyticsService.startAnalytics();
  } else {
    AnalyticsService.stopAnalytics();
  }
}

const onDecline = () => {
  AnalyticsService.stopAnalytics();
}

export interface RoutedProps<State = any> {
  location: State;
  navigate: Location;
}


export function withRouter( Child: any ) {
  return ( props: JSX.IntrinsicAttributes ) => {
    const location = useLocation();
    const navigate = useNavigate();
    return <Child { ...props } navigate={ navigate } location={ location } />;
  }
}


function NotFound() {
  return (
    <Container>
      <Box sx={{ display:"flex", alignItems:"center", justifyContent:"center", minHeight:"60vh", flexDirection:"column"}}>
      <Typography variant="h1">404 - Not found</Typography>
      <Typography variant="h3">The page you are looking for does not exist</Typography>
      </Box>
    </Container>
  );
}

export default App;
