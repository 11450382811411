import React from "react";
import { TyreImageComponent } from "./TyreImageComponent";
import { Box } from "@mui/material";

export type TyreInfoProps = {
    tyreType: number,
    tyreAgeLaps: number,
}


export class TyreInfoComponent extends React.Component<TyreInfoProps> {

    render(): React.ReactNode {
        return (<Box sx={{display: "flex", flexDirection:"column", alignItems:"center"}}>
            <TyreImageComponent tyre={this.props.tyreType} />
            <div className="tyreAge">{this.props.tyreAgeLaps}</div>
        </Box>)
    }
}