import { useMemo, useState } from "react";
import { useChartDimensions } from "./ChartUtils";
import * as d3 from "d3";
import { Box, Button} from "@mui/material";


interface DriverChartProps {
    driverData: {
        name: string;
        team: number;
        position: number;
        values: number[];

    }[];
    xLabel?: string;
    yLabel?: string;
    children?: React.ReactNode;
}

const chartSettings = {
    marginLeft: 40,
    marginRight: 40,
    marginTop: 40,
    marginBottom: 40,
}

export default function DriverChartComponent({ driverData, xLabel, yLabel, children }: DriverChartProps) {


    const [ref,dimensions] = useChartDimensions(chartSettings);

    // const [isFullScreen, setIsFullScreen] = useState(false);
    // draw a graph of the lap times of all drivers over the laps driven

    // line colors , use team colors
    let colors = ["#00a19c", "#a6051a", "#121f45", "#041E42", "#00352f", "#005ba9", "#00293f", "#f9f2f2", "#ff8000", "#981E32"];

    //compute for each driver if they are the first or second driver of their team, by their position
    let teamMap = [0,0,0,0,0,0,0,0,0,0];
    let isFirst = driverData.map(d => {
      let team = d.team!;
      if (teamMap[team] === 0) {
        teamMap[team] = 1;
        return true;
      } else {
        return false;
      }
    });

    const driverCount = driverData.length;
    //create boolean array to store if driver is shown or not
    const [showDriver, setShowDriver] = useState(new Array(driverCount).fill(true));

    let maxLength = d3.max(driverData.map(d => d.values.length))!;
    
    let xScale = d3.scaleLinear()
      .domain([0, maxLength-1])
      .range([0, dimensions.boundedWidth!]);

    
    const plotData = useMemo(() => {
        return driverData.map((d,i) =>  {return {values: d.values.filter(v => !isNaN(v)), idx: i}}).filter((d, i) => showDriver[i]);
    }, [driverData, showDriver]);

    const plotValues = plotData.map(d => d.values);
    let minLapTime = d3.min(plotValues.flat())!;
    let maxLapTime = d3.max(plotValues.flat())!;

    let yScale = d3.scaleLinear()
      .domain([minLapTime, maxLapTime])
      .range([0, dimensions.boundedHeight!]);

    let line = d3.line()
      .x((d, i) => xScale(d[0]))
      .y(d => yScale(d[1]!));

    const ticks = useMemo(() => {

        return xScale.ticks(maxLength).map(value => ({
            value,
            xOffset: xScale(value)
        }));
    }, [maxLength, xScale]);


    const verticalTicks = useMemo(() => {
        return yScale.ticks(10).map(value => ({
            value,
            yOffset: yScale(value)
        }));
    }, [yScale]);

    const handleToggleDriver = (e: React.MouseEvent<HTMLButtonElement>) => {
        const index = driverData.findIndex(d => d.name === e.currentTarget.id);
        let newShowDriver = [...showDriver];
        newShowDriver[index] = !newShowDriver[index];
        setShowDriver(newShowDriver);
    }

    // const handleToggleDriverSVG = (e: React.MouseEvent<SVGPathElement>) => {
    //     const index = driverData.findIndex(d => d.position.toString() === e.currentTarget.id);
    //     let newShowDriver = [...showDriver];
    //     newShowDriver[index] = !newShowDriver[index];
    //     setShowDriver(newShowDriver);
    // }

    // const handleOpenFullScreen = (open: boolean) => {
    //     setIsFullScreen(open);
    // }

    return (
        // <FullscreenChartComponent handleOpenChange={handleOpenFullScreen} >
    <div>
        <div ref={ref} style={{height: "500px"}}>
                <svg viewBox={`0 0 ${dimensions.width??0} ${dimensions.height??0}`} style={{maxHeight:"100%", width:"100%"}}>
                    <rect width={dimensions.width} height={dimensions.height} fill="#000"/>
    
                    <g 
                        transform={`translate(${dimensions.marginLeft}, ${dimensions.marginTop})`}
                    >
                        {plotData.map((d, i) => 
                            <path 
                                stroke={colors[driverData[d.idx].team!]}
                                fill="none"
                                strokeWidth={1}
                                strokeDasharray={!isFirst[d.idx] ? "5,5" : "0,0"}
                                d={line(d.values.map((l, i) => [i, l]))?.toString()}
                                key={d.idx}
                            >                                
                            </path>)}
                        
                        <path
                            stroke="#fff"
                            strokeWidth={1}
                            d={`M 0 0 H ${dimensions.boundedWidth}`}
                            transform={`translate(0, ${dimensions.boundedHeight})`}
                        />
                        {ticks.map(({ value, xOffset }) => (
                            <g transform={`translate(${xOffset}, ${dimensions.boundedHeight})`}
                                key={"hTick"+value}
                            >
                                <text
                                    key={value}
                                    style={{ fontSize: 10 }}
                                    dy="1em"
                                    y={10}
                                    x={0}
                                    fill="#fff"
                                    textAnchor="middle"
                                >
                                    {value+1}
                                </text>
                            </g>
                        ))}
                        <text
                            style={{ fontSize: 10 }}
                            dy="1em"
                            y={dimensions.boundedHeight! + 20}
                            x={dimensions.boundedWidth!/2}
                            fill="#fff"
                            textAnchor="end"
                        >
                            {xLabel}
                        </text>
    
                        <path
                            stroke="#fff"
                            strokeWidth={1}
                            d={`M 0 0 V ${dimensions.boundedHeight}`}
                        />
    
                        {verticalTicks.map(({ value, yOffset }) => (
                            <g transform={`translate(0, ${yOffset})`}
                                key={"vTick"+value}
                            >
                                <text
                                    key={value}
                                    style={{ fontSize: 10 }}
                                    dy="1em"
                                    y={0}
                                    x={-10}
                                    fill="#fff"
                                    textAnchor="end"
                                >
                                    {value.toFixed(1)}
                                </text>
                            </g>
                        ))}
                        <g
                            x={-50}
                            y={dimensions.boundedHeight!/2}
                        >
                        <text
                            style={{ fontSize: "1em" }}
                            dy="1em"
                            transform="rotate(-90)"
                            fill="#fff"
                            textAnchor="end"
                        >
                            {yLabel}
                        </text>
                        </g>
                    </g>
                    <g
                        transform={`translate(${dimensions.marginLeft}, ${dimensions.height!-dimensions.marginBottom!})`}
                    >
                        {/* {driverData.map((d, i) => 
                        <g
                            transform={`translate(${(i%10)*50}, ${50+((i > 9) as unknown as number)*20})`}
                            onClick={handleToggleDriverSVG}
                            id={d.position?.toString()}
                            opacity={showDriver[i] ? 1 : 0.5}
                            key={i}
                            >
                            <path
                                d="M3,0 L40,0 A3,3 0 0,1 43,3 L43,7 A3,3 0 0,1 40,10 L3,10 A3,3 0 0,1 0,7 L0,3 A3,3 0 0,1 3,0"
                                stroke="#fff"
                                transform="translate(-2, -9)"
                                height={"1em"}
                            />
                            <text 
                                fill="#fff"
                                fontSize="1em"
                                // dy="1em"
                                // dx={10+i*50}
                                // y={50}
                            >
                                {d.name.substring(0,3)}
                            </text>
                            <path
                                stroke={colors[d.team!]}
                                strokeWidth={2}
                                d={`M 0 0 H 10`}
                                transform={`translate(${25}, -5)`}
                                strokeDasharray={!isFirst[i] ? "2,2" : "0,0"}
                            />
                        </g>
                        )}
    
    
                        <g
                            transform={`translate(${dimensions.boundedWidth! -30}, ${50+((driverCount > 9) as unknown as number)*5})`}
                        >
                            
                        <path
                                d="M3,0 L40,0 A3,3 0 0,1 43,3 L43,7 A3,3 0 0,1 40,10 L3,10 A3,3 0 0,1 0,7 L0,3 A3,3 0 0,1 3,0"
                                stroke="#fff"
                                transform="translate(-2, 0)"
                                scale={"1em"}
                            />
                        <text
                            fill="#fff"
                            fontSize="1em"
                            dy="1em"
                            onClick={() => {
                                const allShown = showDriver.every(driver => driver);
                                setShowDriver(showDriver.map(() => !allShown));
                            }
                            }
                        >
                            Toggle All
                        </text>
                        </g> */}
                    </g>
                </svg>
                </div>
    
                <Box>
                {driverData.map((d, i) => 
                        <Button style={{}} onClick={handleToggleDriver} key={i} id={d.name} variant="contained" sx={{margin:"5px", opacity: showDriver[i]? "1": "0.5"}} size="small">
                            {d.name}
                            <svg width="15" height="10">
                                <line x1="5" y1="5" x2="15" y2="5" stroke={colors[d.team!]} strokeWidth="2" strokeDasharray={!isFirst[i] ? "2,2" : "0,0"} color={colors[d.team!]}/>
                            </svg>
                        </Button>)
    
                }
                                    <Button onClick={() => {
                        const allShown = showDriver.every(driver => driver);
                        setShowDriver(showDriver.map(() => !allShown));
                    }} variant="contained" sx={{margin:"5px"}} size="small">
                        {"Toggle All"}
                    </Button>
                </Box>
    
    
                <Box sx={{display: "flex", justifyContent: "center", alignItems: "start"}}>
                {children}
    
                
                {/* <Box>
                    {driverData.map((d,i) => <Button style={{color: colors[d.team!]}} onClick={handleToggleDriver} key={i}>{d.name}</Button>)}
                    
                    <Button onClick={() => {
                        const allShown = showDriver.every(driver => driver);
                        setShowDriver(showDriver.map(() => !allShown));
                    }}>
                        {"Toggle All"}
                    </Button>
                </Box> */}
    
                {/* <Button onClick={handleOpenFullScreen} startIcon={<FullscreenIcon/>} variant="contained" size="large">
                </Button> */}
                {/* <IconButton onClick={handleOpenFullScreen} size="large">
                    <FullscreenIcon />
                </IconButton> */}
                </Box>
        
        </div>
        // </FullscreenChartComponent>
    
    );
}