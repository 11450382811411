import { createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";

export type UserLoginInfo = {
    email: string,
    password: string,
}

export const loginUser = createAsyncThunk(
    'user/loginUser',
    async ( loginInfo:UserLoginInfo, { rejectWithValue }) => {
        try {
            let result = await AuthService.loginUser(loginInfo.email, loginInfo.password);
            return result;
        } catch (error: any) {
            return rejectWithValue(error);
        }

    });

export const logoutUser = createAsyncThunk('user/logoutUser', () => {
    AuthService.logout();
    return;
});


export const loadUserDataFromLocalStorage = createAsyncThunk('user/loadUserDataFromLocalStorage', () => {
    return AuthService.isLoggedIn;
});


export const loadUserInformation = createAsyncThunk('user/loadUserInformation', () => {
    return UserService.getUserInfo();
});

export const refreshUserInformation = createAsyncThunk('user/refreshUserInformation', () => {
    AuthService.refreshToken();
    return UserService.getUserInfo();
});