import React, { Component } from "react";
import { RoutedProps } from "../App";
import { LapTime } from "../components/LapTimeComponent";
import { SessionType } from "../TelemetryInterfaces";
import { TrackInfoComponent } from "../components/TrackInfoComponent";
import { DiffTime } from "../components/DiffTimeComponent";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import cx from "classnames";
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Collapse, Container, IconButton, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from "@mui/material";
import { PenaltyType, ResultStatus, Session, SessionDriver, SessionsClient, WarningType } from "../ApiClient";
import { TyreImageComponent } from "../components/TyreImageComponent";
import { getSessionNameFromType } from "../shared/utils";
import { Link } from "react-router-dom";
import GapTimeChartComponent from "../components/charts/GapTimeChartComponent";
import LapTimesChartComponent from "../components/charts/LapTimesChartComponent";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WeatherChart from "../components/charts/WeatherChartComponent";
import { WeatherImageComponent } from "../components/WeatherImageComponent";

type State = {
  session?: Session,
  relatedSessions?: Session[],
  tabValue: number
}

export class SessionComponent extends Component<RoutedProps, State> {

  constructor(props: RoutedProps) {
    super(props);
    this.state = { session: undefined, tabValue: 0 };
  }

  componentDidMount() {

    document.title = "Pitwall Companion - Session";
    let sessionsClient = new SessionsClient();
    sessionsClient.getId(this.props.location.pathname.split("/")[2]).then((response) => {
      this.setState({ session: response });
      sessionsClient.getSessionsForSameWeekend(response.weekendLinkIdentifier!.toString()).then((sessions) => {
        this.setState({ relatedSessions: sessions });
      }).catch((error) => {
        // Do nothing
      });
    });
    // }
  }

  componentDidUpdate(prevProps: Readonly<RoutedProps<any>>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      let sessionsClient = new SessionsClient();
      sessionsClient.getId(this.props.location.pathname.split("/")[2]).then((response) => {
        this.setState({ session: response });
        if(response.weekendLinkIdentifier! > 0){
        sessionsClient.getSessionsForSameWeekend(response.weekendLinkIdentifier!.toString()).then((sessions) => {
          this.setState({ relatedSessions: sessions });
        }).catch((error) => {
          // Do nothing
        });
      }
      });
    }


  }

  static getTeamClass(teamId: number) {
    switch (teamId) {
      case 0:
        return "team-mercedes";
      case 1:
        return "team-ferrari";
      case 2:
        return "team-redbull";
      case 3:
        return "team-williams";
      case 4:
        return "team-aston-martin";
      case 5:
        return "team-alpine";
      case 6:
        return "team-alpha";
      case 7:
        return "team-haas";
      case 8:
        return "team-mclaren";
      case 9:
        return "team-sauber";

      default:
        return "team";
    }
  }

  render(): React.ReactNode {

    if (this.state.session === undefined) {
      return <div></div>
    }
    let drivers = this.state.session.drivers;
    let leaderLapCount = 0;
    let bestLapTime = 0;
    let bestLapTimeIdx = -1;

    if (drivers === undefined) {
      drivers = [];
    }
    let sortedDrivers = drivers.sort((x, y) => x.position! - y.position!);
    if (!sortedDrivers) {
      return <div></div>
    }

    if (sortedDrivers.length > 0) {
      leaderLapCount = sortedDrivers[0].lapTimes!.length;
      bestLapTime = Math.min(...sortedDrivers.map(d => (d.bestLapTimeMs! > 0 ? d.bestLapTimeMs! : 999999999)));
      bestLapTimeIdx = sortedDrivers.map(d => d.bestLapTimeMs!).findIndex(l => l === bestLapTime);
    }

    // console.log("bestLapTime",bestLapTime)
    // console.log("bestLapTimeIdx",bestLapTimeIdx)

    let startDate = new Date(this.state.session.sessionStartTime!);

    let duration = this.state.session.sessionEndTime! - this.state.session.sessionStartTime!;

    let formatter = new Intl.DateTimeFormat(navigator.language, { hour: 'numeric', minute: 'numeric', hour12: false, weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' });

    let showRelatedSessions = this.state.relatedSessions?.length! > 1;

    let airTempText = this.state.session.startAirTemp! > 0 ? `Air: ${this.state.session.startAirTemp}°C` : "Air: N/A";
    let trackTempText = this.state.session.startTrackTemp! > 0 ? `Track: ${this.state.session.startTrackTemp}°C` : "Track: N/A";

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
      this.setState({ tabValue: newValue });
    };

    return (<div>
      <Container maxWidth="lg">
        {/* <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <Typography variant="h3">{SessionType[this.state.session?.sessionType!]}</Typography>
                    <Typography variant="h3">{formatter.format(startDate)}</Typography>
                    <TrackInfoComponent track={this.state.session?.trackId!}></TrackInfoComponent>
                    <Typography variant="h3">Laps: {this.state.session?.numberOfLaps}</Typography>
                </Box> */}

        <Paper sx={{ margin: "10px 0", padding: "10px" }}>
          <Typography variant="h3">Session Results</Typography>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", padding: "10px", flex: "1 1 40%" }}>

              <Typography variant="h5">Type: {getSessionNameFromType(this.state.session.sessionType!)}</Typography>
              <Typography variant="h5">Date: {formatter.format(startDate)}</Typography>
              <Typography variant="h5">Duration: {<LapTime time={duration} />}</Typography>
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography variant="h5">Track: </Typography><TrackInfoComponent format="compact" track={this.state.session?.trackId!}></TrackInfoComponent>
              </Box>
              <Typography variant="h5">Laps: {leaderLapCount}</Typography>
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography variant="h5">Best Lap: </Typography><Typography variant="h5"><LapTime time={bestLapTime}></LapTime></Typography>
              </Box>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", padding: "10px", flex: "1 1 40%" }}>
              <Typography variant="h5">Weather: <WeatherImageComponent weather={this.state.session.startWeather!}></WeatherImageComponent></Typography>
              <Typography variant="h5">{airTempText}</Typography>
              <Typography variant="h5">{trackTempText}</Typography>
              <Typography variant="h5">Safety Cars: {this.state.session.numberOfSafetyCars}</Typography>
              <Typography variant="h5">Virtual Safety Cars: {this.state.session.numberOfVirtualSafetyCars}</Typography>
              <Typography variant="h5">Red Flags: {this.state.session.numberOfRedFlags}</Typography>
              <Typography variant="h5">Overtakes: {this.state.session.overtakes?.length}</Typography>

            </Box>

            {showRelatedSessions &&
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", padding: "10px", flex: "1 1 40%" }}>
                <Typography variant="h5">Related Sessions</Typography>
                <ul>
                  {this.state.relatedSessions?.map(s => {
                    let infoElement;
                    if (s.sessionType === SessionType.Race || s.sessionType === SessionType.Race2) {
                      infoElement = <Typography variant="h6" sx={{ display: "inline-block" }}>Laps: {s.numberOfLaps}</Typography>
                    } else {
                      infoElement = <Typography variant="h6" sx={{ display: "inline-block" }}> {<LapTime time={s.sessionEndTime! - s.sessionStartTime!} showMilliseconds={false} />} minutes</Typography>
                    }
                    return <li key={s.id} style={{ listStyle: "none", margin: "5px" }}>
                      {/* <Link to={"/sessions/" + s.id}> */}
                      <Box component={Link} to={"/sessions/" + s.id} sx={{ textDecoration: "none" }}>
                        <Card elevation={5} sx={{ padding: "5px", margin: "10px 0" , textDecoration:"none"}}>
                          <Typography variant="h6">{getSessionNameFromType(s.sessionType!)} - {infoElement}</Typography>
                          
                          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Box>
                          <Typography variant="h6">Air:{s.startAirTemp} </Typography>
                          <Typography variant="h6">Track:{s.startTrackTemp} </Typography>
                          </Box>
                          <WeatherImageComponent weather={s.startWeather!}></WeatherImageComponent>
                          </Box>
                        </Card>
                      </Box>
                      {/* </Link> */}
                    </li>
                  })}
                </ul>
              </Box>
            }
          </Box>

        </Paper>

        {this.state.session.drivers &&
          <div>

            <Accordion>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h5">Charts</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={this.state.tabValue} aria-label="basic tabs example" onChange={handleTabChange} textColor="secondary">
                    <Tab label="Gap Time Chart" {...a11yProps(0)} />
                    <Tab label="Lap Times Chart"  {...a11yProps(1)} />
                    <Tab label="Weather Chart" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={this.state.tabValue} index={0}>
                  <GapTimeChartComponent driverData={this.state.session.drivers!}></GapTimeChartComponent>
                </CustomTabPanel>
                <CustomTabPanel value={this.state.tabValue} index={1}>
                  <LapTimesChartComponent driverData={this.state.session.drivers!}></LapTimesChartComponent>
                </CustomTabPanel>
                <CustomTabPanel value={this.state.tabValue} index={2}>
                  {(this.state.session.weatherHistory === undefined || this.state.session.weatherHistory.length === 0) ? <Typography variant="h6">No weather data available</Typography>
                  :
                  <WeatherChart weatherData={this.state.session.weatherHistory!}></WeatherChart>}
                </CustomTabPanel>
              </AccordionDetails>
            </Accordion>
          </div>
        }

        <Paper sx={{ margin: "10px 0" }}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Position</TableCell>
                  <TableCell>Driver</TableCell>
                  <TableCell>Number</TableCell>
                  <TableCell>Team</TableCell>
                  <TableCell>Laps</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Gap</TableCell>
                  <TableCell>Fastest Lap</TableCell>
                  <TableCell>Pos gain</TableCell>
                  <TableCell>Penalties</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedDrivers.map((driver, idx) => {
                  return <Row driver={driver} idx={idx} totalLaps={leaderLapCount} totalRaceTime={sortedDrivers[0].totalRaceTime!} bestLapTimeIdx={bestLapTimeIdx}></Row>
                }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

      </Container>
    </div>);
  }
}

function Row(props: { driver: SessionDriver, idx: number, totalLaps: number, totalRaceTime: number, bestLapTimeIdx: number }) {
  const [open, setOpen] = React.useState(false);

  const formatTimeDouble = (timeInSec: number | undefined) => {
    if (timeInSec === undefined) return "--.--.---";
    let minutes = Math.floor(timeInSec / 60);
    let seconds = Math.floor(timeInSec % 60);
    let milliseconds = Math.floor((timeInSec - Math.floor(timeInSec)) * 1000);
    return `${minutes}:${seconds.toString().padStart(2, "0")}.${milliseconds.toString().padStart(3, "0")}`;

  }

  let positionChange = props.driver.startingPosition! - props.driver.finishingPosition!;

  let positionChangeColor = "inherit";
  if (positionChange > 0) {
    positionChangeColor = "green";
  } else if (positionChange < 0) {
    positionChangeColor = "red";
  }
  let arrowIcon = null;
  if (positionChange > 0) {
    arrowIcon = <KeyboardArrowUpIcon htmlColor={positionChangeColor} />;
  } else if (positionChange < 0) {
    arrowIcon = <KeyboardArrowDownIcon htmlColor={positionChangeColor} />;
  }

  let raceTimeElement = props.idx === 0 ? <div></div> :
    <DiffTime time={props.driver.totalRaceTime! * 1000} leaderTime={props.totalRaceTime * 1000} lapNumber={props.driver.lapTimes!.length} leaderLap={props.totalLaps}></DiffTime>

  if (props.driver.resultStatus === ResultStatus.DidNotFinish) {
    raceTimeElement = <Typography variant="body1">DNF</Typography>
  }
  else if (props.driver.resultStatus === ResultStatus.Disqualified) {
    raceTimeElement = <Typography variant="body1">DSQ</Typography>
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>{props.driver.position}</TableCell>
        <TableCell>{props.driver.name}</TableCell>
        <TableCell>{props.driver.carNumber}</TableCell>
        <TableCell className={SessionComponent.getTeamClass(props.driver.team!)}> <div className={SessionComponent.getTeamClass(props.driver.team!)} /></TableCell>
        <TableCell>{props.driver.numberOfLaps}</TableCell>
        <TableCell><LapTime time={props.driver.totalRaceTime! * 1000}></LapTime> </TableCell>
        <TableCell>
          {raceTimeElement}
        </TableCell>
        <TableCell className={cx({ "best": props.idx === props.bestLapTimeIdx })}><LapTime time={props.driver.bestLapTimeMs!} /></TableCell>
        <TableCell>
          <Box sx={{ color: positionChangeColor, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            {arrowIcon}{positionChange}
          </Box></TableCell>
        <TableCell>{props.driver.totalPenaltiesSeconds! > 0 ? `+${props.driver.totalPenaltiesSeconds}s` : "-"}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Container sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-evenly", width: "100%", paddingTop: "10px", flexWrap: "wrap" }}>
              <Box sx={{ flex: "0 1 40%" }}>
                <Typography variant="h6" gutterBottom component="div">
                  Lap Times
                </Typography>
                <Table size="small" aria-label="lap times">
                  <TableHead>
                    <TableRow>
                      <TableCell>Lap</TableCell>
                      <TableCell>Sector 1</TableCell>
                      <TableCell>Sector 2</TableCell>
                      <TableCell>Sector 3</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.driver.lapTimes!.map((historyRow, idx) => (
                      <TableRow key={idx}>
                        <TableCell component="th" scope="row">
                          <LapTime time={historyRow.lapTime!} isInvalid={historyRow.invalidLap}></LapTime>
                        </TableCell>
                        <TableCell>
                          <LapTime time={historyRow.sector1Time!} isInvalid={historyRow.invalidLap}></LapTime>
                        </TableCell>
                        <TableCell>
                          <LapTime time={historyRow.sector2Time!} isInvalid={historyRow.invalidLap}></LapTime>
                        </TableCell>
                        <TableCell>
                          <LapTime time={historyRow.sector3Time!} isInvalid={historyRow.invalidLap}></LapTime>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
              <Box sx={{ flex: "0 1 40%" }}>
                <Typography variant="h6" gutterBottom component="div">Warnings</Typography>
                <Table size="small" aria-label="penalties">
                  <TableHead>
                    <TableRow>
                      <TableCell>Lap</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.driver.warnings!.length === 0 ? <TableRow><TableCell colSpan={3}>No warnings</TableCell></TableRow> :
                      props.driver.warnings!.map((warning, idx) => (
                        <TableRow key={idx}>
                          <TableCell>{warning.lapNum}</TableCell>
                          <TableCell>{WarningType[warning.warningType!]}</TableCell>
                          <TableCell>{formatTimeDouble(warning.warningTime)}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
              <Box sx={{ flex: "0 1 40%" }}>
                <Typography variant="h6" gutterBottom component="div">Penalties</Typography>
                <Table size="small" aria-label="penalties">
                  <TableHead>
                    <TableRow>
                      <TableCell>Lap</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.driver.penalties!.length === 0 ? <TableRow><TableCell colSpan={3}>No penalties</TableCell></TableRow> :
                      props.driver.penalties!.map((warning, idx) => (
                        <TableRow key={idx}>
                          <TableCell>{warning.lapNum}</TableCell>
                          <TableCell>{PenaltyType[warning.penaltyType!]}</TableCell>
                          <TableCell>{warning.penaltyType! === PenaltyType.Warning ? "-" : formatTimeDouble(warning.penaltyTime)}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>

              <Box sx={{ flex: "0 1 40%" }}>
                <Typography variant="h6" gutterBottom component="div">Tyres</Typography>
                <Table size="small" aria-label="penalties">
                  <TableHead>
                    <TableRow>
                      <TableCell>Tyre Type</TableCell>
                      <TableCell>Pitstop Lap</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.driver.tyres!.map((tyreSet, idx) => (
                      <TableRow key={idx}>
                        <TableCell><TyreImageComponent tyre={tyreSet.tyreCompound!} /></TableCell>
                        <TableCell>{tyreSet.endLap === 255 ? "-" : tyreSet.endLap}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Container>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}