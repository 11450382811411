import { createSlice } from '@reduxjs/toolkit'
import { loadUserDataFromLocalStorage, loadUserInformation, loginUser, logoutUser } from './userActions';
import { UserInfo } from '../../ApiClient';
import { push } from "redux-first-history";


const initialState : {
    loading: boolean,
    success: boolean,
    error: any,
    userInfo: UserInfo | null,
    isLoggedIn: boolean,
}= {
    loading: false,
    success: false,
    error: null,
    userInfo: null,
    isLoggedIn: false,
}

export const loggedInSlice = createSlice({
    name: 'loggedIn',
    initialState: initialState,
    reducers: {
        login: (state) => {
        // state.value = true;
        },
        logout: (state) => {
            state.userInfo = null;
            state.success = false;
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loginUser.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true;
            state.isLoggedIn = true;
        });
        builder.addCase(loginUser.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(loginUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action as any;
            state.isLoggedIn = false;
        });
        builder.addCase(logoutUser.fulfilled, (state, action) => {
            state.loading = false;
            state.success = false;
            state.userInfo = null;
            state.isLoggedIn = false;
        });
        builder.addCase(loadUserDataFromLocalStorage.fulfilled, (state, action) => {
            state.loading = false;
            state.success = action.payload;
        });
        builder.addCase(loadUserInformation.fulfilled, (state, action) => {
            state.loading = false;
            state.userInfo = action.payload;
        });
    }
});

export const userInfoMiddleware = (store: any) => (next: any) => (action: any) => {
    if(action.type === loginUser.fulfilled.type || action.type === loadUserDataFromLocalStorage.fulfilled.type) {
        store.dispatch(loadUserInformation());
    }
    if(action.type === loginUser.fulfilled.type){
        store.dispatch(push('/profile'));
    }
    next(action);

}


// Action creators are generated for each case reducer function
export const { login, logout } = loggedInSlice.actions

export default loggedInSlice.reducer