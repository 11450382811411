import React from "react";
import "./trackInfo.scss"
import { Track } from "../TelemetryInterfaces";

export function getFlagIcon(track: Track) {

    let src = "http://purecatamphetamine.github.io/country-flag-icons/3x2/";
    switch (track) {
        case Track.Melbourne:
            src += "AU"
            break;
        case Track.PaulRicard:
            src += "FR";
            break;
        case Track.Shanghai:
            src += "CN";
            break;
        case Track.Sakhir:
            src += "BH";
            break;
        case Track.Catalunya:
            src += "ES";
            break;
        case Track.Monaco:
            src += "MC";
            break;
        case Track.Montreal:
            src += "CA";
            break;
        case Track.Silverstone:
            src += "GB";
            break;
        case Track.Hockenheim:
            src += "DE";
            break;
        case Track.Hungaroring:
            src += "HU";
            break;
        case Track.Spa:
            src += "BE";
            break;
        case Track.Monza:
            src += "IT";
            break;
        case Track.Singapore:
            src += "SG";
            break;
        case Track.Suzuka:
            src += "JP";
            break;
        case Track.AbuDhabi:
            src += "AE";
            break;
        case Track.Texas:
            src += "US";
            break;
        case Track.Brazil:
            src += "BR";
            break;
        case Track.Austria:
            src += "AT";
            break;
        case Track.Sochi:
            src += "RU";
            break;
        case Track.Mexico:
            src += "MX";
            break;
        case Track.Baku:
            src += "AZ";
            break;
        case Track.Zandvoort:
            src += "NL";
            break;
        case Track.Imola:
            src += "IT";
            break;
        case Track.Portimao:
            src += "PT";
            break;
        case Track.Jeddah:
            src += "SA";
            break;
        case Track.Miami:
            src += "US";
            break;
        case Track.LasVegs:
            src += "US";
            break;
        case Track.Qatar:
            src += "QA";
            break;

        case Track.Hanoi:
            src += "VN";
            break;
        case Track.SakhirShort:
            src += "BH";
            break;
        case Track.SilverstoneShort:
            src += "GB";
            break;
        case Track.TexasShort:
            src += "US";
            break;
        case Track.SuzukaShort:
            src += "JP";
            break;

        default:
            src += "DE"
            break;
    }
    src += ".svg"

    return src
}



export function TrackInfoComponent({track, format="full"}: { track: Track,  format?: "compact" | "full"| "small"}) {    

        let isFull = format === "full";
        let isSmall = format === "small";

        return (<div className="trackInfo" style={{flexDirection: isFull? "column": "row-reverse", justifyContent: isFull? "center": "flex-end"}}>
            <div style={{margin: "0 .5em"}}>{Track[track]}</div>
             <img
            alt={Track[track] }
            src={getFlagIcon(track)} 
            style={{width: !isSmall? "": "2em", height: !isSmall? "auto": "2em"}}
            /></div>)
    
}